import { Statuses, rulesetTableRender } from "./ruleEditor";
import { FC, useCallback, useEffect, useState } from "react";
import { Button, Combobox, Table, Column, Drawer, Search, Input, TextArea, Modal, Select, DropdownButton } from "@appkit4/react-components";
import Loader from "components/common/loader";
import Paginate from "components/common/paginate";
import ToolTip from "components/common/tooltip";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TransactionType } from "types/analysis";
import { RuleFunction, RuleObject, RuleStatus, RuleTransaction } from "types/ruleset";
import { checkFormValues, searchFilter } from "services/common";
import { ConfirmationModal, FormError, LoaderWrapper, toastMessage, ValidationError } from "components/common/helpers";
import { SelectValue } from "@appkit4/react-components/esm/combobox/Combobox";
import { FetchClients } from "queries/hooks/administration/client";
import { useQueryClient } from "@tanstack/react-query";
import { DeleteFunction, FetchFunctions, FetchTransactions, PostFunction, PutFunction } from "queries/hooks/ruleset/function";

const initFunction: RuleFunction = {
  functionId: "new",
  identifier: "",
  description: "",
  clientId: "00000000-0000-0000-0000-000000000000",
  versionNumber: 1,
  versionStatus: RuleStatus.Draft,
  versionComments: "Initial version",
  transactions: []
}

const FunctionEditor: FC = () => {
  const [deleteVisible, setDeleteVisible] = useState<RuleFunction>();
  const [editItem, setEditItem] = useState<RuleFunction>();
  const [search, setSearch] = useState("");
  const [clientFilter, setClientFilter] = useState<string>();
  const [statusFilter, setStatusFilter] = useState<number>();
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(25);

  const { functionId } = useParams();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { data: clients, isPending: isClientsPending, error: errorClients } = FetchClients();
  const { data: functions, isPending: isFunctionsPending, error: errorFunctions } = FetchFunctions();
  const { mutate: deleteFunction } = DeleteFunction();

  const getData = useCallback(() => {
    return functions?.filter(f => searchFilter(f, ["identifier", "description"], search))
      .filter(f => !clientFilter || searchFilter(f, ["clientId"], clientFilter))
      .filter(f => statusFilter === undefined || f.versionStatus === statusFilter)
      .map(m => ({ ...m, actions: "" })) || [];
  }, [search, functions, clientFilter, statusFilter]);

  const getTotalPages = useCallback(() => Math.ceil((functions?.length || offset) / offset), [functions, offset]);

  const handleDelete = async () => {
    if (!deleteVisible) return;
    deleteFunction(deleteVisible, {
      onSuccess: () => {
        toastMessage("Function deleted");
        queryClient.invalidateQueries({ queryKey: ["functions"] });
        setDeleteVisible(undefined);
      },
      onError: () => {
        toastMessage("Unable to delete function", "error");
      }
    });
  }


  const copyFunction = (functionItem: RuleFunction) => {
    let newFunction = { ...functionItem, functionId: "new", identifier: "Copy of " + functionItem.identifier, versionNumber: 1, versionStatus: 0, versionComments: "Copy of " + functionItem.versionComments, _functionId: functionItem.functionId };
    setEditItem(newFunction);
  }

  const close = () => {
    navigate("/ruleset/functions");
    setEditItem(undefined);
  }
  useEffect(() => {
    if (functionId) {
      let item = functions?.find(f => f.functionId === functionId);
      if (item) {
        setEditItem(item);
      }
    }
  }, [functionId, functions]);
  return <LoaderWrapper loading={[isClientsPending, isFunctionsPending]} errors={[errorClients, errorFunctions]} inline>
        <h3 id="list-top">Functions</h3>
        <div className="flex items-center gap-4 mt-4 mb-4">
          <div className="shrink">
            <Button kind='primary' icon="icon-plus-outline" onClick={() => {
              setEditItem(initFunction);
            }}>New function</Button>
          </div>
          <div>
            <Search
              searchType={"secondary"}
              onChange={(value: string) => {
                setSearch(value);
              }}
              searchValue={search}
              className="list-filter"
            />
          </div>
          <div>
            <Combobox
              placeholder="Filter by client"
              data={clients?.map(client => ({ label: client.name, value: client.clientId })).concat({ label: "Default", value: "00000000-0000-0000-0000-000000000000" }) || []}
              value={clientFilter}
              onSelect={value => setClientFilter(value.toString())}
            />
          </div>
          <div>
            <Combobox
              placeholder="Status"
              data={Statuses}
              value={statusFilter}
              onSelect={value => setStatusFilter(value as number)}
              onClear={() => setStatusFilter(undefined)}
            />
          </div>
        </div>
        <Table
          originalData={getData()}
          hasTitle
          striped
          currentPage={currentPage}
          pageSize={offset}
        >
          <Column field="identifier" sortKey="identifier" renderCell={(row: RuleFunction, field: string) => (
            <Link to={`/ruleset/functions/${row.functionId}`}>
              <b>{row.identifier}</b>
              <p>{row.description}</p>
            </Link>
          )}></Column>
          <Column field="clientId" sortKey="clientId" renderCell={(row: RuleFunction, field: string) => rulesetTableRender(row, field, clients || [])}>Client</Column>
          <Column field="versionNumber" sortKey="versionNumber" renderCell={(row: RuleFunction, field: string) => rulesetTableRender(row, field, clients || [])}>Version</Column>
          <Column field="versionStatus" sortKey="versionStatus" renderCell={(row: RuleFunction, field: string) => rulesetTableRender(row, field, clients || [])}>Status</Column>
          <Column field="actions" sortKey="actions" renderCell={(row: RuleFunction, field: string) =>
            <DropdownButton
              compact
              kind="tertiary"
              splitButton
              data={[
                { label: "Edit", value: 1 },
                { label: "Make a copy", value: 2 },
                { label: "Delete", value: 3, disabled: row.versionStatus === RuleStatus.Current }
              ]}
              onSelect={(value) => {
                console.log(value)
                switch (value) {
                  case 1:
                    navigate(`/ruleset/functions/${row.functionId}`);
                    break;
                  case 2:
                    copyFunction(row);
                    break;
                  case 3:
                    setDeleteVisible(row);
                    break;
                  default:
                    break;
                }
              }}
              onClick={() => { navigate(`/ruleset/functions/${row.functionId}`) }}
            >
              Edit
            </DropdownButton>
          }>Actions</Column>
        </Table>
        <Paginate
          getTotalPages={getTotalPages()}
          currentPage={currentPage}
          pageOffset={offset}
          setCurrentPage={setCurrentPage}
          setPageOffset={setOffset}
        />
        <ConfirmationModal
          visible={deleteVisible !== undefined}
          title="Delete test"
          cancel={() => setDeleteVisible(undefined)}
          confirm={async () => await handleDelete()}>
          <p>Are you sure you want to delete {deleteVisible?.identifier || "this function"}?</p>
        </ConfirmationModal>
        <Drawer
          visible={editItem !== undefined}
          initialFocus={true}
          mask={true}
          resizable={true}
          placement="right"
          title={editItem?.functionId === "new" ? "New function" : editItem?.identifier || "Edit function"}
          onClose={close}
          style={{ minWidth: 1166 }}
        >
          {editItem && <FunctionForm item={editItem} close={close} />}
        </Drawer>
      </LoaderWrapper>
}

const FunctionForm: FC<{ item: RuleFunction, close: () => void }> = ({ item, close }) => {
  const [transactions, setTransactions] = useState<RuleTransaction[]>();
  const [data, setData] = useState<RuleFunction>(item);
  const [search, setSearch] = useState("");
  const [errors, setErrors] = useState<(keyof RuleFunction)[]>();
  const [editTransaction, setEditTransaction] = useState<RuleTransaction | undefined>();

  const queryClient = useQueryClient();

  const { data: clients } = FetchClients();
  const { data: transactionData } = FetchTransactions(
    item._functionId
      ? item._functionId
      : item.functionId === "new"
        ? undefined
        : item.functionId
  );
  const { mutate: addFunction, isPending: addingFunction } = PostFunction();
  const { mutate: updateFunction, isPending: updatingFunction } = PutFunction();

  const validateForm = () => {
    setErrors(undefined);
    let errorList: (keyof RuleFunction)[] = [];
    let check = checkFormValues(data, ["clientId", "identifier", "description", "versionComments", "versionStatus"]);
    let transactionCheck = (transactions?.length ?? 0) > 0;
    if (Array.isArray(check))
      errorList = check;
    if (!transactionCheck) errorList.push("transactions");
    if (errorList.length > 0) {
      setErrors(errorList);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    let tempData = data;
    data.transactions = transactions;
    if (data.functionId === "new") {
      addFunction(tempData, {
        onSuccess: () => {
          toastMessage("Function added");
          queryClient.invalidateQueries({ queryKey: ["functions"] });
          close();
        },
        onError: () => {
          setErrors(["functionId"]);
        }
      });
    } else {
      updateFunction(tempData, {
        onSuccess: () => {
          toastMessage("Function updated");
          queryClient.invalidateQueries({ queryKey: ["functions"] });
          queryClient.invalidateQueries({ queryKey: ["transactions", tempData.functionId] });
          close();
        },
        onError: () => {
          setErrors(["functionId"]);
        }
      });
    }
  }

  const copyTransaction = (transaction: RuleTransaction) => {
    let newTransaction = { ...transaction, transactionId: crypto.randomUUID(), identifier: "Copy of " + transaction.identifier, functionId: item.functionId, type: transaction.type };
    setEditTransaction(newTransaction);
  }

  const handleTransaction = (transaction: RuleTransaction) => {
    if (!transaction.transactionId) {
      let transactionId = crypto.randomUUID();
      let newTransaction = { ...transaction, transactionId: transactionId, functionId: item.functionId, objects: transaction.objects?.map(m => ({ ...m, transactionId: transactionId })) || [] };
      setTransactions([...transactions || [], newTransaction]);
    } else {
      let newTransactions = transactions?.map(m => m.transactionId === transaction.transactionId ? ({ ...transaction, objects: transaction.objects?.map(m => ({ ...m, transactionId: transaction.transactionId })) || [] }) : m);
      setTransactions(newTransactions);
    }
    setEditTransaction(undefined);
  }

  const handleClose = () => {
    setEditTransaction(undefined);
  }

  const deleteTransaction = (transaction: RuleTransaction) => {
    setTransactions(transactions?.filter(f => f.transactionId !== transaction.transactionId));
  }

  useEffect(() => {
    if (transactionData) {
      if (data._functionId) {
        let copyTransactions: RuleTransaction[] = [];
        for (let transaction of transactionData) {
          let transactionId = crypto.randomUUID();
          transaction.functionId = undefined;
          transaction.transactionId = transactionId
          transaction.objects = transaction.objects?.map(m => ({ ...m, transactionId: transactionId, objectId: crypto.randomUUID() }));
          copyTransactions.push(transaction);
        }
        setTransactions(copyTransactions || []);
      } else {
        setTransactions(transactionData || []);
      }
    }
  }, [transactionData, data._functionId]);
  return (!data
    ? <Loader loadingType="circular" />
    : <div className="ap-input-form">
      <h3 className="ap-font-medium">Details</h3>
      <form>
      <div className="flex gap-4 flex-wrap">
      <div className="grow">
            <Input
              title="Identifier"
              type="text"
              value={data?.identifier}
              onChange={(value: string) => setData({ ...data, identifier: value })}
              className="ap-field"
              required
              error={errors?.includes("identifier")}
              errorNode={errors?.includes("identifier") && <ValidationError error="Identifier is required" />}
            />
          </div>
          <div className="basis-1/3">
            <Select
              placeholder="Status"
              data={Statuses}
              defaultValue={data.versionStatus}
              value={data.versionStatus as SelectValue}
              onSelect={value => setData({ ...data, versionStatus: value as RuleStatus })}
              required
              error={errors?.includes("versionStatus")}
              errorNode={errors?.includes("versionStatus") && <ValidationError error="Status is required" />}
            />
          </div>
          <div className="basis-1/3 break-after-column">
            <Combobox
              placeholder="Client"
              data={clients?.map(client => ({ label: client.name, value: client.clientId })).concat({ label: "Default", value: "00000000-0000-0000-0000-000000000000" })}
              value={data.clientId}
              onSelect={value => setData({ ...data, clientId: value.toString() })}
              className="ap-field"
              required
              error={errors?.includes("clientId")}
              errorNode={errors?.includes("clientId") && <ValidationError error="Client is required" />}
            />
          </div>
          <div className="basis-1/2">
            <TextArea
              title="Description"
              value={data.description}
              onChange={(value: string) => setData({ ...data, description: value })}
              className="ap-field"
              required
              error={errors?.includes("description")}
              errorNode={errors?.includes("description") && <ValidationError error="Description is required" />}
            />
          </div>
          <div className="grow">
            <TextArea
              title="Version comments"
              value={data.versionComments}
              onChange={(value: string) => setData({ ...data, versionComments: value })}
              className="ap-field"
              required
              error={errors?.includes("versionComments")}
              errorNode={errors?.includes("versionComments") && <ValidationError error="Version comments is required" />}
            />
          </div>
          </div>
      </form>
      <h3 className="ap-font-medium">Transactions</h3>
      <div className="flex items-center gap-4 mt-4 mb-4">
      <div className="shrink">
          <Button kind='primary' icon="icon-plus-outline" onClick={() => {
            setEditTransaction({ functionId: data.functionId, identifier: "", description: "", type: TransactionType.T });
          }}>New Transaction</Button>
        </div>
        <div>
          <Search
            searchType={"secondary"}
            onChange={(value: string) => {
              setSearch(value);
            }}
            searchValue={search}
            className="list-filter"
          />
        </div>
      </div>
      {
        !transactions
          ? <Loader loadingType="circular"></Loader>
          : transactions.length === 0
            ? <p>No transactions</p>
            : <>
              <Table
                originalData={transactions.map(m => ({ ...m, actions: "" })).filter(f => searchFilter(f, ["identifier", "description"], search)) || []}
                hasTitle
                striped
              >
                <Column field="identifier" sortKey="identifier">Identifier</Column>
                <Column field="description" sortKey="description">Description</Column>
                <Column field="objects" sortKey="objects" renderCell={(row: RuleTransaction, field: string) => {
                  return row.objects?.length || 0;
                }}>Objects</Column>
                <Column field="actions" sortKey="actions" renderCell={(row: RuleTransaction, field: string) =>
                  <DropdownButton
                    compact
                    kind="tertiary"
                    splitButton
                    data={[
                      { label: "Edit", value: 1 },
                      { label: "Make a copy", value: 2 },
                      { label: "Delete", value: 3 }
                    ]}
                    onSelect={(value) => {
                      switch (value) {
                        case 1:
                          setEditTransaction(row);
                          break;
                        case 2:
                          copyTransaction(row);
                          break;
                        case 3:
                          deleteTransaction(row);
                          break;
                        default:
                          break;
                      }
                    }}
                    onClick={() => { setEditTransaction(row); }}
                  >
                    Edit
                  </DropdownButton>
                }>Actions</Column>
              </Table>
            </>
      }
      {errors?.includes("transactions") && <ValidationError error="At least one transaction is required" />}
      {errors?.includes("functionId") && <FormError error="Unable to save function" />}
      <div className="ap-footer flex gap-4">
        <div className="grow">
          <Button onClick={close} kind="secondary">Cancel</Button>
        </div>
        <div>
          <Button onClick={handleSubmit} kind="primary" loading={addingFunction || updatingFunction}>Save</Button>
        </div>
      </div>
      <Modal
        visible={editTransaction !== undefined}
        title={editTransaction?.transactionId ? "Edit transaction" : "New transaction"}
        onCancel={() => setEditTransaction(undefined)}
        maskCloseable={false}
      >
        {editTransaction && <TransactionEditor transaction={editTransaction} close={handleClose} submit={handleTransaction} />}
      </Modal>
    </div>
  );
}

const TransactionEditor: FC<{ transaction: RuleTransaction, close: () => void, submit: (transaction: RuleTransaction) => void }> = ({ transaction, close, submit }) => {
  const [data, setData] = useState<RuleTransaction>(transaction);
  const [objects, setObjects] = useState<RuleObject[]>(transaction.objects || []);
  const [errors, setErrors] = useState<{ index: number, property: string }[]>();

  const handleSubmit = () => {
    if (validateObjects()) {
      let newTransaction = { ...data, objects };
      submit(newTransaction);
    }
  }
  const validateObjects = () => {
    let errors: { index: number, property: string }[] = [];
    objects.forEach((object, index) => {
      if (!object.authObject) {
        errors.push({ index, property: "authObject" });
      }
      if (!object.field) {
        errors.push({ index, property: "field" });
      }
      if (!object.matchValues) {
        errors.push({ index, property: "matchValues" });
      }
    });
    if (errors.length === 0) return true;
    else {
      setErrors(errors);
      return false;
    }
  }
  useEffect(() => {
    if (!data)
      setData(transaction);
    if (!objects)
      setObjects(transaction.objects || []);
  }, [data, objects, transaction]);
  return (
    <div className="ap-input-form">
      <form>
      <div className="ap-footer flex gap-4">
        <div className="basis-1/3">
            <Input
              title="Identifier"
              type="text"
              value={data?.identifier}
              onChange={(value: string) => setData({ ...data, identifier: value })}
              className="ap-field"
              required
            />
          </div>
          <div className="basis-1/3">
            <Input
              title="Description"
              type="text"
              value={data?.description}
              onChange={(value: string) => setData({ ...data, description: value })}
              className="ap-field"
              required
            />
          </div>
          <div className="grow">
            <Select
              placeholder="Type"
              required
              defaultValue={TransactionType.T}
              value={data?.type}
              data={[
                {
                  label: TransactionType.T,
                  value: "T"
                },
                {
                  label: TransactionType.S,
                  value: "S"
                },
                {
                  label: TransactionType.R,
                  value: "R"
                }
              ]}
              onSelect={(value) => setData({ ...data, type: value.toString() })}
              dropdownRenderMode="portal"
            />
          </div>
        </div>
        {objects.map((object, index) =>
        (
          <div className="flex gap-4" key={index}>
            <div>
              <Input
                title="Object"
                type="text"
                value={object.authObject}
                onChange={(value: string) => {
                  let newObjects = objects?.map((m, i) => i === index ? { ...m, authObject: value, transactionId: transaction.transactionId } : m);
                  setObjects(newObjects);
                }}
                className="ap-field"
                error={errors?.find(f => f.index === index && f.property === "authObject")}
                errorNode={errors?.find(f => f.index === index && f.property === "authObject") && <ValidationError error="Auth object is required" />}
                required
              />
            </div>
            <div>
              <Input
                title="Field"
                type="text"
                value={object.field}
                onChange={(value: string) => {
                  let newObjects = objects?.map((m, i) => i === index ? { ...m, field: value, transactionId: transaction.transactionId } : m);
                  setObjects(newObjects);
                }}
                className="ap-field"
                error={errors?.find(f => f.index === index && f.property === "field")}
                errorNode={errors?.find(f => f.index === index && f.property === "field") && <ValidationError error="Field is required" />}
                required
              />
            </div>
            <div>
              <ToolTip content={() => <><p>Comma separated values</p><p>Use <b>*</b> to match all.</p><p>Use <b>**</b> to match * specifically.</p></>} position="right">
                <Input
                  title="Match values"
                  type="text"
                  value={object.matchValues}
                  onChange={(value: string) => {
                    let newObjects = objects?.map((m, i) => i === index ? { ...m, matchValues: value, transactionId: transaction.transactionId } : m);
                    setObjects(newObjects);
                  }}
                  className="ap-field"
                  error={errors?.find(f => f.index === index && f.property === "matchValues")}
                  errorNode={errors?.find(f => f.index === index && f.property === "matchValues") && <ValidationError error="Match values is required" />}
                  required
                />
              </ToolTip>
            </div>
            <div>
              <ToolTip content="Delete object" position="bottom">
                <span className="Appkit4-icon icon-delete-fill pointer" style={{ marginRight: 8, marginTop: 20 }} onClick={() => {
                  setObjects(objects?.filter((f, i) => i !== index));
                }}></span>
              </ToolTip>
            </div>
          </div>
        ))}
        <Button kind="secondary" onClick={() => { setObjects([...objects || [], { authObject: "", field: "", matchValues: "", transactionId: transaction.transactionId }]) }} icon="icon-plus-outline">Add object</Button>
      </form>
      <div className="ap-footer flex gap-4">
        <div className="grow">
          <Button onClick={close} kind="secondary">Cancel</Button>
        </div>
        <div>
          <Button onClick={handleSubmit} kind="primary">Save</Button>
        </div>
      </div>
    </div>
  )
}
export default FunctionEditor;