import { AnalysisNavigator, Chevron, LoaderWrapper, TestInfo, toastMessage } from "components/common/helpers";
import AnalysisTemplate from "components/layout/analysisTemplate";
import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BusinessProcess, RuleBag, RuleFunction, RuleObject, RuleTransaction, UserLevel } from "types/analysis";
import { RuleSetFlatData, TestData } from "types/user";
import ToolTip from "components/common/tooltip";
import { Button, Column, Panel, Search, Tab, Table, Tabs } from "@appkit4/react-components";
import Paginate from "components/common/paginate";
import { searchFilter, sortValues } from "services/common";
import { exportExcel } from "services/export";
import { rulesetHeaders } from "types/exportHeaders";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import { PwCJwt } from "types/common";
import { FetchRuleset } from "queries/hooks/analysis/analysis";

const Ruleset: FC = () => {
  const { analysisId } = useParams();
  const [flatData, setFlatData] = useState<RuleSetFlatData[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const [cookie] = useCookies();

  const checkUserLevel = (level: UserLevel) => {
    const jwtData = jwtDecode<PwCJwt>(cookie["id_token"]);
    return jwtData?.userLevel && UserLevel[jwtData.userLevel as keyof typeof UserLevel] <= level;
  }

  const { data, isPending, error } = FetchRuleset(analysisId);

  const getFlatData = useCallback((bag: RuleBag, businessProcesses: BusinessProcess[]) => {
    var flat = [];
    for (let set of bag.sets)
      for (let test of set.tests)
        if (test.functions)
          for (let f of test.functions)
            if (f.transactions)
              for (let transaction of f.transactions)
                if (transaction.objects)
                  for (let object of transaction.objects) {
                    flat.push({
                      test: test.identifier,
                      testType: test.type.toString(),
                      businessProcess: businessProcesses?.find(f => f.businessProcessId === test.businessProcessId)?.name || "",
                      function: f.identifier,
                      functionDescription: f.description,
                      transaction: transaction.identifier,
                      transactionDescription: transaction.description,
                      authObject: object.authObject,
                      field: object.field,
                      testValues: object.matchValues
                    })
                  }
    return flat.sort((a, b) => a.businessProcess > b.businessProcess ? 1 : -1);
  }, []);

  const exportRuleset = () => {
    if (!analysisId) return;
    if (!checkUserLevel(UserLevel.PwCUser)) return;
    let pageHeaders = [
      ["Insights - Access Risks"],
      ["Rule Set Name", data?.ruleBag?.name || ""],
      ["Rule Set Description", data?.ruleBag?.description || ""]
    ]
    toastMessage("Exporting ruleset");
    exportExcel(flatData, rulesetHeaders, `RuleSet-Export-${data?.ruleBag?.name}`, pageHeaders);
  }

  useEffect(() => {
    if (data) {
      setFlatData(getFlatData(data.ruleBag, data.businessProcesses));
    }
  }, [data, getFlatData]);
  return (
    <AnalysisTemplate>
      <AnalysisNavigator />
      <LoaderWrapper loading={[isPending]} errors={[error]}>
        <div className="flex items-center gap-4">
          <div className="grow">
            <h1>Rule set</h1>
          </div>
          {checkUserLevel(UserLevel.PwCAdmin)
            ? <div>
              <Button onClick={exportRuleset}><span className="Appkit4-icon icon-table-data-outline"></span>Export</Button>
            </div>
            : null
          }
        </div>
        <Tabs activeIndex={activeIndex} onTabChange={(index: number) => setActiveIndex(index)}>
          <Tab label="Tree view" value="0">
            {
              (activeIndex === 0 && data) && <TreeView processes={data?.businessProcesses} ruleset={data?.ruleBag} />
            }
          </Tab>
          <Tab label="Rule set table" value="1" disabled={!checkUserLevel(UserLevel.PwCUser)}>
            {
              activeIndex === 1 && <TableView flatData={flatData} />
            }
          </Tab>
        </Tabs>
      </LoaderWrapper>
    </AnalysisTemplate>
  );
}

const FunctionItem: FC<{ ruleFunction: RuleFunction, open: boolean, onClick?: () => void }> = ({ ruleFunction, open, onClick }) => (
  <div
    className="ap-accordion-open flex items-center p-4 gap-4"
  >
    <div className="basis-32 pl-16">
      <Chevron open={open} onClick={onClick} />
    </div>
    <div>
      <b>{ruleFunction.identifier}</b>
      <p>{ruleFunction.description}</p>
    </div>
  </div>
)

const TransactionItem: FC<{ transaction: RuleTransaction, open: boolean, onClick: () => void }> = ({ transaction, open, onClick }) => (
  <div className="ap-accordion-open flex p-4 gap-4 items-center">
    <div className="basis-32 pl-36">
      <Chevron open={open} onClick={onClick} />
    </div>
    <div className="basis-96">
      <b>{transaction.identifier}</b>
      <p>{transaction.description}</p>
    </div>
    <div className="grow">
      {transaction.type === "T"
        ? <ToolTip content="Transaction"><span className="Appkit4-icon icon-convert-outline"></span></ToolTip>
        : <ToolTip content="Special transaction"><span className="Appkit4-icon icon-star-outline"></span></ToolTip>
      }
    </div>
  </div>
)

const TreeView: FC<{ processes: BusinessProcess[], ruleset: RuleBag }> = ({ processes, ruleset }) => {
  const [testIds, setTestIds] = useState<string[]>([]);
  const [functionIds, setFunctionIds] = useState<string[]>([]);
  const [transactionIds, setTransactionIds] = useState<string[]>([]);
  const [businessProcessIds, setBusinessProcessIds] = useState<string[]>([]);

  const getTests = useCallback(() => {
    return ruleset?.sets.flatMap(m => m.tests);
  }, [ruleset])
  const renderValues = (row: RuleObject) => (
    <>
      {row.matchValues.split(",").map(m => <span className="ap-match-value" key={`${row.objectId}${m}`}>{m}</span>)}
    </>
  )
  return (
    <>
      <div
        className="ap-list-header ap-business-process-header flex items-center gap-4 p-4 pt-6 pb-6">
        <div className="basis-12"></div>
        <div>
          Business process
        </div>
      </div>
      {processes.sort((a, b) => a.abbreviation > b.abbreviation ? 1 : -1).filter(f => getTests()?.map(m => m.businessProcessId).includes(f.businessProcessId)).map((process, index) => (
        <div className="list-row" key={process.businessProcessId}>
          <div
            className="flex items-center gap-4 p-4"
            key={process.businessProcessId}
          >
            <div className="basis-12">
              <Chevron open={businessProcessIds.includes(process.businessProcessId)}
                onClick={() => businessProcessIds.includes(process.businessProcessId) ? setBusinessProcessIds(businessProcessIds.filter(f => f !== process.businessProcessId)) : setBusinessProcessIds([...businessProcessIds || [], process.businessProcessId])}
              />
            </div>
            <div>
              <b>{process.name}</b>
              <p>{process.description}</p>
            </div>
          </div>
          <div>
            {
              businessProcessIds.includes(process.businessProcessId) && (
                <>
                  <div
                    className="ap-list-header ap-accordion-open ap-business-process-test-header flex items-center gap-4 p-4">
                    <div className="basis-12"></div>
                    <div className="basis-96">
                      Test
                    </div>
                    <div className="basis-20">
                      Test type
                    </div>
                    <div className="grow">
                      Functions
                    </div>
                  </div>
                  {getTests()?.filter(f => f.businessProcessId === process.businessProcessId)
                    .sort((a, b) => sortValues(a.identifier, b.identifier))
                    .map((test, index) => (
                      <div className="list-row-1 pointer" key={test.testId}>
                        <TestInfo
                          test={test as TestData} open={testIds.includes(test.testId)} key={test.testId}
                          onClick={() => testIds.includes(test.testId)
                            ? setTestIds(testIds.filter(f => f !== test.testId))
                            : setTestIds([...testIds || [], test.testId])}
                        />
                        {testIds.includes(test.testId) && test.functions?.map(f => (
                          <div className="list-row" key={f.functionId}>
                            <FunctionItem ruleFunction={f} open={functionIds.includes(f.functionId)} onClick={() => functionIds.includes(f.functionId) ? setFunctionIds(functionIds.filter(m => m !== f.functionId)) : setFunctionIds([...functionIds || [], f.functionId])} />
                            {functionIds.includes(f.functionId) && f.transactions?.sort((a, b) => sortValues(a.identifier, b.identifier)).map(transaction => (
                              <div className="list-row-1" key={transaction.transactionId}>
                                <TransactionItem
                                  transaction={transaction}
                                  open={transactionIds.includes(transaction.transactionId || "")}
                                  onClick={() =>
                                    transaction.transactionId && transactionIds.includes(transaction.transactionId)
                                      ? setTransactionIds(transactionIds.filter(f => f !== transaction.transactionId))
                                      : setTransactionIds([...transactionIds || [], transaction.transactionId || ""])}
                                />
                                {transactionIds.includes(transaction.transactionId || "") && (
                                  <div
                                    className="ap-accordion-open flex gap-4 p-4 pl-48"
                                  >
                                    <Table
                                      className="ap-table-round"
                                      originalData={transaction.objects}
                                      hasTitle
                                      striped
                                      condensed
                                    >
                                      <Column field="authObject" sortKey="object">Object</Column>
                                      <Column field="field" sortKey="field">Field</Column>
                                      <Column field="matchValues" sortKey="matchValues" renderCell={renderValues}>Test values</Column>
                                    </Table>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>))}
                      </div>
                    ))}
                </>
              )}
          </div>
        </div>
      ))}
    </>
  )
}

const TableView: FC<{ flatData: RuleSetFlatData[] }> = ({ flatData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState<RuleSetFlatData[]>([]);
  const [offset, setOffset] = useState(100);
  const [search, setSearch] = useState("");

  const getTotalPages = useCallback(() => flatData ? Math.ceil(filteredData.length / offset) : 1, [flatData, offset, filteredData]);

  const customRender = (row: RuleSetFlatData, field: string) => {
    switch (field as keyof RuleSetFlatData) {
      case "function":
        return (
          <>
            <b>{row.function}</b>
            <p className="text-nowrap">{row.functionDescription}</p>
          </>
        );
      case "transaction":
        return (
          <>
            <b>{row.transaction}</b>
            <p className="text-nowrap">{row.transactionDescription}</p>
          </>
        )
      case "testValues":
        return(<>
        {row.testValues.split(",").map(m => <span className="ap-match-value" key={`${row.transaction}${row.authObject}${row.field}${m}`}>{m}</span>)}
      </>);
      default:
        return row[field as keyof RuleSetFlatData];
    }
  }
  useEffect(() => {
    setFilteredData(
      flatData
        ? flatData.filter(f => searchFilter(f, ["businessProcess", "transaction", "function", "authObject", "businessProcess", "test", "transactionDescription", "field", "testValues"], search))
        : []
    );
  }, [search, flatData]);
  return (
    <Panel style={{ paddingTop: 24 }} id="list-top">
      <div className="flex items-center gap-4 mt-4 mb-4">
        <div>
          <Search
            searchType={"secondary"}
            onChange={(value: string) => {
              setSearch(value);
              if (currentPage !== 1)
                setCurrentPage(1);
            }}
            className="list-filter"
          />
        </div>
      </div>
      <Table
        className="ap-table-round"
        originalData={filteredData}
        hasTitle
        striped
        condensed
        currentPage={currentPage}
        pageSize={offset}
      >
        <Column field="businessProcess" sortKey="businessProcess">Business process</Column>
        <Column field="test" sortKey="test">Test</Column>
        <Column field="testType" sortKey="testType">Type</Column>
        <Column field="function" sortKey="function" renderCell={customRender}>Function</Column>
        <Column field="transaction" sortKey="transaction" renderCell={customRender}>Transaction</Column>
        <Column field="authObject" sortKey="authObject">Object</Column>
        <Column field="field" sortKey="field">Field</Column>
        <Column field="testValues" sortKey="testValues" renderCell={customRender}>Test values</Column>
      </Table>
      <Paginate
        getTotalPages={getTotalPages()}
        currentPage={currentPage}
        pageOffset={offset}
        setCurrentPage={setCurrentPage}
        setPageOffset={setOffset}
      />
    </Panel>
  )
}

export default Ruleset;