import { FC } from "react";
import AnalysisTemplate from "components/layout/analysisTemplate";
import { useParams } from "react-router-dom";
import TransactionList from "./transactionList";
import { AnalysisNavigator, LoaderWrapper } from "components/common/helpers";
import { Breadcrumb, BreadcrumbItem } from "@appkit4/react-components";
import { useAtomValue } from "jotai";
import { filterAtom } from "jotai/store";
import { FetchTransactions } from "queries/hooks/analysis/transaction";

const Transactions: FC = () => {
  const { analysisId, transactionId } = useParams();

  const userFilter = useAtomValue(filterAtom);

  const { data, isPending, error } = FetchTransactions(analysisId, userFilter);

  console.log(isPending, error);
  return (
    <AnalysisTemplate>
      <LoaderWrapper loading={[isPending]} errors={[error]} inline>
        <Breadcrumb>
          <BreadcrumbItem>Analysis</BreadcrumbItem>
          <BreadcrumbItem>Transactions</BreadcrumbItem>
          {transactionId && <BreadcrumbItem>{data?.find(f => f.transactionId === transactionId)?.identifier}</BreadcrumbItem>}
        </Breadcrumb>
        <AnalysisNavigator />

        <h1>By Transaction</h1>
        <TransactionList
          transactionList={data || []}
        />
      </LoaderWrapper>
    </AnalysisTemplate>
  );
}

export default Transactions;
