import { Column, Combobox, Panel, Switch, Tab, Table, Tabs } from "@appkit4/react-components";
import { FC, useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { themeColours } from "style/theme";
import { Bar, BarChart, Cell, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import ToolTip from "components/common/tooltip";
import { useAtomValue } from "jotai";
import { filterAtom } from "jotai/store";
import { FetchTestsGraph } from "queries/hooks/analysis/graphs";
import { BusinessProcessStatistics, TestStatistics } from "types/graphs";
import { customTooltip, LoaderWrapper } from "components/common/helpers";

type BarData = {
  name: string;
  value: number;
  process: string;
  testId: string;
  description: string[];
}

const ByProcessGraph: FC = () => {
  const { analysisId } = useParams();
  const [graphs, setGraphs] = useState(false);
  const [barData, setBarData] = useState<BarData[]>();
  const [process, setProcess] = useState<string>();
  const [activeIndex, setActiveIndex] = useState(0);

  const navigate = useNavigate();

  const userFilter = useAtomValue(filterAtom);

  const { data, isPending, error } = FetchTestsGraph(analysisId, userFilter);

  const getChartData = (data: BusinessProcessStatistics, index: number) => {
    return [
      { name: "One or more test matches", value: data.userCount },
      { name: "No Matches", value: (data.userTotal - data.userCount) }
    ];
  }
  const filterByTestType = useCallback((test: TestStatistics) => {
    switch (activeIndex) {
      case 1:
        return test.type === "SA";
      case 2:
        return test.type === "SoD";
      default:
        return true;
    }
  }, [activeIndex]);

  const userCountSort = (a: TestStatistics, b: TestStatistics) => a.userCount > b.userCount ? -1 : 0;

  const getBarData = useCallback((businessProcessId?: string) => {
    let processes = data?.map(m => ({ label: m.name, value: m.businessProcessId, testIds: m.tests.map(m => m.testId) }));
    var result = businessProcessId
      ? data?.find(f => f.businessProcessId === businessProcessId)?.tests.filter(filterByTestType).sort(userCountSort).slice(0, 9)
        .map(test => ({ name: test.identifier, testId: test.testId, value: test.userCount, process: processes?.find(f => f.value === businessProcessId)?.label || "", description: test.functions }))
      : data?.flatMap(m => m.tests).filter(filterByTestType).sort(userCountSort).slice(0, 9)
        .map(test => ({ name: test.identifier, testId: test.testId, value: test.userCount, process: processes?.find(f => f.testIds.includes(test.testId))?.label || "", description: test.functions }));

    if (result) {
      setBarData(result);
    }
  }, [data, filterByTestType]);

  const renderCustomizedLabel = (data: any) => {
    const { index, percent } = data;
    return (
      <text x={"52%"} y={"52%"} textAnchor="middle" dominantBaseline="middle" style={{ fontSize: 24, fontWeight: 500, fontFamily: '"PwC Helvetica Neue", sans-serif' }}>
        {index === 0 && `${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  useEffect(() => {
    getBarData(process);
  }, [process, activeIndex, getBarData]);
  return (
    <Panel
      title="% of users with test matches per Business Process"
      extra={(
        <ToolTip content={() => (
          <>
            <h3>How to view</h3>
            <p style={{ margin: "8px 0" }}>Each donut graph represents a different business process and the segments show the number of users.</p>
            <p style={{ margin: "8px 0" }}>The table lists the top 10 tests with the most user matches. Results can also be viewed as a bar graph.</p>
          </>
        )} position="left">
          <span className="Appkit4-icon icon-information-fill ap-font-16 ap-panel-information"></span>
        </ToolTip>
      )}
    >
      <LoaderWrapper loading={[isPending]} errors={[error]} inline>
        <div className="2xl:columns-4 md:columns-4">
          {data && data.map((graph: BusinessProcessStatistics, i) => (
            <div className="h-60 w-40 shrink" key={i}>
              <ResponsiveContainer minWidth={160} height={160}>
                <PieChart>
                  <Pie
                    activeIndex={0}
                    key={graph.businessProcessId}
                    data={getChartData(graph, i)}
                    dataKey="value"
                    nameKey="name"
                    cx={"50%"}
                    cy={"50%"}
                    innerRadius={40}
                    outerRadius={70}
                    accentHeight={10}
                    paddingAngle={5}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    onClick={() => navigate(`/analysis/${analysisId}/businessProcess/${graph.businessProcessId}`)}
                  >
                    <Cell fill={themeColours.ORANGE} />
                    <Cell fill={"#D1D1D1"} />
                  </Pie>
                  <Tooltip
                    wrapperStyle={{ zIndex: 2999 }}
                    allowEscapeViewBox={{ x: false, y: true }}
                    content={customTooltip}
                  />
                </PieChart>
              </ResponsiveContainer>
              <p className="ap-font-medium" style={{ textAlign: "center" }}>{graph.name}</p>
            </div>
          ))}
        </div>
        <div className="flex flex-row flex-nowrap place-items-center">
          <div className="basis-1/4">
            <h2>Top 10</h2>
          </div>
          <div className="basis-1/4">
            <Switch onChange={(value) => setGraphs(value)} showIndicator>View graphs</Switch>
          </div>
          <div className="basis-2/4">
            <Combobox
              data={data?.map(m => ({ label: m.name, value: m.businessProcessId }))}
              value={process} placeholder="All business processes"
              onSelect={(value) => setProcess(value.toString())}
            />
          </div>
        </div>
        <p className="ap-font-medium">Tests by Matches</p>
        <Tabs activeIndex={activeIndex} onTabChange={(index) => setActiveIndex(index)}>
          <Tab label="All"></Tab>
          <Tab label="SA"></Tab>
          <Tab label="SoD"></Tab>
        </Tabs>
        {
          graphs
            ? (
              <ResponsiveContainer width={"100%"} height={400}>
                <BarChart
                  data={barData}
                >
                  <YAxis dataKey="value" />
                  <XAxis dataKey="name" />
                  <Bar dataKey="value" fill="#FD6412">
                    {barData?.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={themeColours.ORANGE} onClick={() => navigate(`/analysis/${analysisId}/users/test/${entry.testId}`)} />
                    ))}
                  </Bar>
                  <Tooltip
                    content={(e) => {
                      if (e.payload && e.payload.length) {
                        let data = e.payload[0].payload;
                        return (
                          <Panel>
                            <p><span className="ap-font-medium">{e.label}</span> {data.description.map((m: string) => <span className="ap-function-description" key={data.label}>{m}</span>)}</p>
                            <span className="ap-font-large">{data.value.toLocaleString()}</span> users with matches
                          </Panel>
                        );
                      } else
                        return null;
                    }}
                  />
                </BarChart>
              </ResponsiveContainer>
            )
            : (barData && <Table
              originalData={barData}
              hasTitle
              striped
              condensed
            >
              <Column
                field="name"
                sortKey="name"
                style={{ width: 128 }}
                renderCell={(data: { testId: number, name: string }) =>
                  <Link to={`/analysis/${analysisId}/users/test/${data.testId}`}>
                    <span className="Appkit4-icon icon-hyperlink-fill"></span> <b>{data.name}</b>
                  </Link>}>
                Test
              </Column>
              <Column field="process" sortKey="process">Process</Column>
              <Column field="description" sortKey="description" renderCell={(data: { label: string, description: string[] }) => <p>{data.description.map((m: string, i: number) => <span className="ap-function-description" key={i}>{m}</span>)}</p>}>Description</Column>
              <Column field="value" sortKey="value" renderCell={(data: { value: number }) => data.value.toLocaleString()}>User count</Column>
            </Table>
            )
        }
      </LoaderWrapper>
    </Panel>
  );
}

export default ByProcessGraph;