import { FC, useCallback, useState } from 'react';
import { Filter } from '@appkit4/react-components/filter';
import { FilterType } from 'types/analysis';
import { Button, Search, Tag } from '@appkit4/react-components';
import 'components/layout/filters.scss';
import { useAtomValue, useSetAtom } from 'jotai';
import { defaultFilter, filterAtom } from 'jotai/store';
import { useParams } from 'react-router-dom';
import { FetchAnalysisData } from 'queries/hooks/analysis/analysis';
import { searchFilter } from 'services/common';

export const TestType = [
  {
    label: "Standard",
    value: 1,
  },
  {
    label: "Only privacy",
    value: 2,
  },
  {
    label: "Privacy",
    value: 4,
  },
  {
    label: "Pricing",
    value: 8
  }
];

const Filters: FC = () => {
  const { analysisId } = useParams();
  const [search, setSearch] = useState<string>("");

  const userFilter = useAtomValue(filterAtom);
  const setUserFilter = useSetAtom(filterAtom);

  const {data: analysisData} = FetchAnalysisData(analysisId);

  const handleSelect = (values: string[], type: FilterType) => {
    switch(type) {
      case FilterType.TESTS:
        setUserFilter({...userFilter, tests: values.map(v => parseInt(v))});
        break;
      case FilterType.BUKRS:
        setUserFilter({...userFilter, bukrs: values});
        break;
      case FilterType.WERKS:
        setUserFilter({...userFilter, werks: values});
        break;
      case FilterType.VKORG:
        setUserFilter({...userFilter, vkorg: values});
        break;
    }
  }
  
  const getData = useCallback((type: FilterType) => {
    return analysisData?.filters?.filter(f => f.type === type && searchFilter(f, ['identifier', 'name'], search))
      .map(f => ({ label: f.identifier, value: f.filterId, desc: f.name })) || [];
  }, [analysisData, search]);

  return (
    <>
      <div style={{ display: "flex" }} className="ap-pattern-search-filter">
        <div className='ap-pattern-search-filter-header'>
          <div className='search-filter-icon'>
            <span className="Appkit4-icon icon-filter-outline"></span>
          </div>
          <div className="search-filter-description">
            <div className='description-title'>Analysis filter</div>
            <div className='description-content'>Use the filters to get the results you want to see.</div>
          </div>
          <div className='filter-header-hr'></div>
        </div>
        <Search style={{ margin: 8 }} placeholder="Search filters" value={search} onChange={(value) => setSearch(value)} searchType="secondary"/>
        <div className="search-menu">
          <Filter
            title="Test types"
            onSelect={(values: string[]) => handleSelect(values, FilterType.TESTS)}
            data={TestType}
            value={userFilter.tests}
            multiple={true}
            expand={true}
          />
          <Filter
            title="Company codes"
            onSelect={(values: string[]) => handleSelect(values, FilterType.BUKRS)}
            data={getData(FilterType.BUKRS)}
            value={userFilter.bukrs}
            multiple={true}
            expand={true}
          />
          <Filter
            title="Plants"
            onSelect={(values: string[]) => handleSelect(values, FilterType.WERKS)}
            data={getData(FilterType.WERKS)}
            value={userFilter.werks}
            multiple={true}
            expand={true}
          />
          <Filter
            title="Sales organizations"
            onSelect={(values: string[]) => handleSelect(values, FilterType.VKORG)}
            data={getData(FilterType.VKORG)}
            value={userFilter.vkorg}
            multiple={true}
            expand={true}
          />
        </div>
      </div>
    </>
  )
}

export const ActiveFilters: FC<{ rows: number }> = ({ rows }) => {
  const { analysisId } = useParams();
  const userFilter = useAtomValue(filterAtom);
  const setUserFilter = useSetAtom(filterAtom);

  const {data: analysisData} = FetchAnalysisData(analysisId);

  const checkIfAnyFilters = useCallback(() => {
    return (userFilter.bukrs && userFilter.bukrs.length > 0) ||
      (userFilter.werks && userFilter.werks.length > 0) ||
      (userFilter.vkorg && userFilter.vkorg.length > 0);
  },[userFilter]);

  return (
    <div className="flex items-center pt-4 pb-4 gap-4">
      <div>
        <p className="list-item-count">{rows} items</p>
      </div>
      {checkIfAnyFilters() && (
          <div>
            <div className="tags-panel-border"></div>
          </div>
      )}
      {checkIfAnyFilters() && (
          <div><span className="Appkit4-icon icon-filter-outline"></span></div>
      )}
      {checkIfAnyFilters() && (
          <div className="grow">
            {userFilter.bukrs && userFilter.bukrs.map((bukrs, index) =>
              <Tag key={index} onClose={() => setUserFilter({...userFilter, bukrs: userFilter.bukrs?.filter(f => f !== bukrs)})}>{analysisData?.filters?.find(f => f.filterId === bukrs)?.identifier}</Tag>
            )}
            {userFilter.tests && userFilter.werks?.map((werks, index) =>
              <Tag key={index} className="ap-tag-werks" onClose={() => setUserFilter({...userFilter, werks: userFilter.werks?.filter(f => f !== werks)})}>{analysisData?.filters?.find(f => f.filterId === werks)?.identifier}</Tag>
            )}
            {userFilter.tests && userFilter.vkorg?.map((vkorg, index) =>
              <Tag key={index} className="ap-tag-vkorg" color="#FFB600" onClose={() => setUserFilter({...userFilter, vkorg: userFilter.vkorg?.filter(f => f !== vkorg)})}>{analysisData?.filters?.find(f => f.filterId === vkorg)?.identifier}</Tag>
            )}
          </div>
      )}
      {checkIfAnyFilters() && (
          <div>
            <div className="tags-panel-border"></div>
          </div>
      )}
      {checkIfAnyFilters() && (
          <div>
            <Button kind="secondary" onClick={() => setUserFilter(defaultFilter)}>Clear filters</Button>
          </div>
      )}
    </div>
  )
}

export default Filters;