import { Pagination, Select } from "@appkit4/react-components"
import { FC } from "react"
import { useSearchParams } from "react-router-dom";

interface PaginateProps {
  currentPage?: number;
  pageOffset?: number;
  getTotalPages: number;
  setCurrentPage?: (value: number) => void;
  setPageOffset?: (value: number) => void;
  noScroll?: boolean;
  scrollTo?: string;
  noPageSet?: boolean;
}

const Paginate: FC<PaginateProps> = ({ getTotalPages, noScroll, setCurrentPage, setPageOffset, currentPage, pageOffset, scrollTo, noPageSet }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const scrollToTop = () => {
    let topElement = document.getElementById(scrollTo || "list-top");
    if (topElement) {
      topElement.scrollIntoView({
        behavior: "smooth",
        inline: "nearest"
      });
    }
  }
  return getTotalPages <= 1
    ? null
    : (
      <div className="flex items-center gap-4">
        <div>
          <Pagination
            style={{ margin: "0 auto" }}
            current={setCurrentPage ? currentPage : parseInt(searchParams.get("p") || "1")}
            total={getTotalPages}
            onPageChange={(page: number) => {
              if (setCurrentPage) {
                setCurrentPage(page);
                scrollToTop();
              } else {
                searchParams.set("p", page.toString())
                setSearchParams(searchParams);
                scrollToTop();
              }
            }}
          />
        </div>
        {!noPageSet && <div>
          <Select
            placeholder="Items per page"
            data={["25", "50", "100"]}
            onSelect={(v) => {
              if (setPageOffset) {
                setPageOffset(parseInt(v.toString()));
                setCurrentPage && setCurrentPage(1);
              } else {
                searchParams.set("p", "1");
                searchParams.set("o", v.toString());
                setSearchParams(searchParams);
              }
              scrollToTop();
            }}
            value={setPageOffset ? pageOffset : searchParams.get("o") || "50"}
            className="pagination-offset"
          />
        </div>}
      </div>
    )
}

export default Paginate;