import { FC, useEffect } from "react"
import AnalysisTemplate from "components/layout/analysisTemplate"
import UserListWrapper from "components/common/userListWrapper"
import { Link, useParams } from "react-router-dom"
import { AnalysisNavigator, LoaderWrapper, TestIcon } from "components/common/helpers"
import UserStatistics from "components/common/userStatistics"
import PercentageBar from "components/common/percentageBar"
import { ListType, RuleFunction, UserListData, UserStats } from "types/analysis"
import { Breadcrumb, BreadcrumbItem, } from "@appkit4/react-components"
import { useAtomValue } from "jotai"
import { filterAtom } from "jotai/store"
import { FetchAnalysisData } from "queries/hooks/analysis/analysis"
import { FetchUserList } from "queries/hooks/analysis/user"

const parseStats = (userList: UserListData): UserStats => ({
  userCount: userList.pagination.items,
  userTotal: userList.pagination.totalItems,
  userDialog: userList.data.filter(f => f.type === "A").length,
  userSystem: userList.data.filter(f => f.type === "B").length,
  userCommunication: userList.data.filter(f => f.type === "C").length,
  userService: userList.data.filter(f => f.type === "S").length,
});

const Users: FC<{ type: ListType }> = ({ type }) => {
  const { analysisId, testId, transactionId, roleId } = useParams();

  const userFilter = useAtomValue(filterAtom);

  const { data: analysisData, isPending: isAnalysisPending, error: errorAnalysis } = FetchAnalysisData(analysisId);

  const { data: userList, isPending: isUsersPending, error: errorUsers } = FetchUserList(analysisId, type, testId || transactionId || roleId || undefined, userFilter);

  const getRuleFunction = () => {
    let tempTests = userList?.tests
    if (tempTests)
      return tempTests[0].functions?.filter(f => f.functionId === userList?.transaction?.functionId)[0];
  }

  const ResultDetails = () => {
    if (!userList) return;
    switch (type) {
      case ListType.Test:
        return <TestInfo userList={userList} />
      case ListType.Transaction:
        return <TransactionInfo userList={userList} ruleFunction={getRuleFunction()} />
      default:
        return <h1>All users</h1>;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  })
  return (
    <AnalysisTemplate>
      <LoaderWrapper loading={[isAnalysisPending, isUsersPending]} errors={[errorAnalysis, errorUsers]}>
      {
        userList && <Breadcrumb>
          <BreadcrumbItem>Analysis</BreadcrumbItem>
          <BreadcrumbItem>Users</BreadcrumbItem>
          {testId && <BreadcrumbItem>{userList?.test?.identifier}</BreadcrumbItem>}
          {transactionId && <BreadcrumbItem>{userList?.transaction?.identifier}</BreadcrumbItem>}
        </Breadcrumb>
      }
      <AnalysisNavigator />
        {
          ResultDetails()
        }
        {(userList && analysisData) && <UserListWrapper analysisData={analysisData} userList={userList} showMatch={testId === undefined && transactionId === undefined && roleId === undefined} />}
      </LoaderWrapper>
    </AnalysisTemplate>
  )
}

const TransactionInfo: FC<{ userList: UserListData, ruleFunction?: RuleFunction }> = ({ userList, ruleFunction }) => (
  <>
    <h1>Transaction</h1>
    <div
      className="ap-list-header ap-business-process-header flex items-center p-5 gap-4">
      <div className="basis-60">
        Test
      </div>
      <div className="basis-60">
        Function
      </div>
      <div className="basis-60">
        Tests
      </div>
      <div className="basis-60">
      </div>
      <div className="grow">
        Distinct user matches
      </div>
    </div>
    <div
      className="list-row-1 flex items-center p-4 gap-4 mb-4"
    >
      <div className="basis-60">
        <b>{userList.transaction?.identifier}</b>
        <p>{userList.transaction?.description}</p>
      </div>
      <div className="basis-60">
        <b>{ruleFunction?.identifier}</b>
        <p>{ruleFunction?.description}</p>
      </div>
      <div className="basis-60">
        {userList.tests?.map((test) => (
          <Link to={`/analysis/${userList.analysisId}/users/test/${test.testId}`} key={`${userList.transactionId}:${test.testId}`}>
            <p key={`${userList.transactionId}-${test}`}><b>{test.identifier}</b> <TestIcon type={test.type} /></p>
          </Link>
        ))}
      </div>
      <div className="basis-60">
        <PercentageBar
          percentage={
            Math.round((userList.pagination.items / userList.pagination.totalItems) * 100)
          }
          dark
        />
      </div>
      <div className="grow">
        <UserStatistics stats={parseStats(userList)} />
      </div>
    </div>
  </>
)

const TestInfo: FC<{ userList: UserListData }> = ({ userList }) => (
  <>
    <h1>Test</h1>
    <div
      className="ap-list-header ap-business-process-header flex items-center p-5 gap-4"
    >
      <div className="basis-96">
        Test
      </div>
      <div className="basis-20">
        Test type
      </div>
      <div className="basis-72">
        Function(s) included in Test
      </div>
      <div className="grow">
        Distinct user matches
      </div>
    </div>
    <div
      className="list-row-1 flex items-center p-5 gap-4 mb-4"
    >
      <div className="basis-96">
        <b>{userList?.test?.identifier}</b>
        <p>{userList.test?.functions?.map(m => <span className="ap-function-description">{m.description}</span>)}</p>
      </div>
      <div className="basis-20">
        <TestIcon type={userList.test?.type || "SA"} />
      </div>
      <div className="basis-72">
        {userList.test?.functions?.map(f => (
          <div key={f.functionId}>
            <b>{f.identifier}</b>
            <p>{f.description}</p>
          </div>
        ))}
      </div>
      <div className="grow">
        <UserStatistics stats={parseStats(userList)} />
      </div>
    </div>
  </>
)


export default Users;
