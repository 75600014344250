import { Badge, Button, Column, Combobox, Drawer, DropdownButton, Input, ItemDataType, Search, Select, Stepper, Steppers, Switch, Table, Tag } from "@appkit4/react-components";
import { ConfirmationModal, FormError, LoaderWrapper, ValidationError, toastMessage } from "components/common/helpers"
import { FC, useState, useCallback, useEffect } from "react"
import { useParams } from "react-router-dom";
import { checkFormValues, searchFilter, selectFilter } from "services/common";
import { shadeRainbow, shadeRainbowText } from "style/theme";
import { Claim, ClaimType, ClientData, SapSystem, SystemUser, UserLevel } from "types/analysis";
import { SelectValue } from "@appkit4/react-components/esm/combobox/Combobox";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import { PwCJwt } from "types/common";
import { userLevels } from "types/user";
import { useQueryClient } from "@tanstack/react-query";
import { FetchClients } from "queries/hooks/administration/client";
import { FetchClientUsers, DeleteClientUser, PostClientUser, PutClientUser } from "queries/hooks/administration/clientUser";
import { FetchSystems } from "queries/hooks/administration/system";
import { ActiveFilters } from "components/layout/filters";

const initUser: SystemUser = {
  userId: "new",
  emailAddress: "",
  firstName: "",
  lastName: "",
  userLevel: undefined,
  isActive: false,
  claims: []
}

const UserView: FC = () => {
  const { clientId } = useParams();
  const [deleteVisible, setDeleteVisible] = useState<SystemUser>();
  const [editVisible, setEditVisible] = useState<SystemUser>();
  const [search, setSearch] = useState("");
  const [clientFilter, setClientFilter] = useState<string[]>();
  const [roleFilter, setRoleFilter] = useState<string[]>();

  const [cookie] = useCookies();

  const queryClient = useQueryClient();

  const { data: clientData, isPending: isPendingClients, error: errorClients } = FetchClients(clientId);

  const { data: userData, isPending: isPendingUsers, error: errorUsers } = FetchClientUsers(clientId);

  const { mutate: deleteUser } = DeleteClientUser();

  const checkUserLevel = (level: UserLevel) => {
    const jwtData = jwtDecode<PwCJwt>(cookie["id_token"]);
    return jwtData?.userLevel && UserLevel[jwtData.userLevel as keyof typeof UserLevel] <= level;
  }

  const handleDelete = async (user?: SystemUser) => {
    if (!user) return;
    deleteUser(user, {
      onSuccess: () => {
        setDeleteVisible(undefined);
        toastMessage("User deleted successfully");
        queryClient.invalidateQueries({ queryKey: ["clientUsers", clientId] });
        return true;
      },
      onError: () => {
        toastMessage("Unable to delete user", null, "error");
      }
    });
  }

  const getData = useCallback(() => {
    if (userData)
      return userData
        .map(m => ({ ...m, userLevel: m.userLevel?.toString(), actions: "", clientAccess: m.claims?.filter(f => f.type === ClaimType.CLIENT).map(m => m.clientId) }))
        .filter(f => searchFilter(f, ["emailAddress", "firstName", "lastName"], search))
        .filter(f => selectFilter(f, "userLevel", roleFilter))
        .filter(f => selectFilter(f, "clientAccess", clientFilter));
    else
      return [];
  }, [userData, search, roleFilter, clientFilter]);

  const customizeColumn = (row: SystemUser, field: string) => {
    switch (field) {
      case "actions":
        if (row.userId)
          return (
            <DropdownButton
              compact
              splitButton
              kind="tertiary"
              data={[
                { label: "Edit user", value: 1, icon: "icon-edit-fill" },
                { label: "Delete user", value: 2, icon: "icon-delete-fill", disabled: !checkUserLevel(UserLevel.PwCAdmin) },
              ]}
              onSelect={(value) => {
                switch (value) {
                  case 1:
                    setEditVisible(row);
                    break;
                  case 2:
                    setDeleteVisible(row);
                    break;
                }
              }}
              onClick={() => setEditVisible(row)}
            >Edit</DropdownButton>
          );
        break;
      case "clientAccess":
        return (<>
          {
            row.clientAccess && row.clientAccess?.length > 0
              ? row.clientAccess?.map((id, i) => (
                <Badge
                  key={id}
                  value={clientData?.find(f => f.clientId === id)?.name}
                  type="info-outlined"
                  className="ap-badge-process"
                />
              ))
              : <Badge
                value={"Full access"}
                type="primary"
                className="ap-badge-process"
              />
          }
        </>)
      case "userLevel":
        return userLevels.find(f => f.value === row.userLevel?.toString())?.label;
      case "lastName":
        return (<>{row.lastName}, {row.firstName}</>)
      case "isActive":
        return row.isActive ? <Badge value="Active" type="success" /> : <Badge value="Inactive" type="danger" />
      default:
        return row[field as keyof SystemUser];
    }
  }

  return (
    <LoaderWrapper loading={[isPendingClients, isPendingUsers]} errors={[errorClients, errorUsers]}>
      <h3>Users</h3>
      {clientId
        ? checkUserLevel(UserLevel.PwCAdmin)
          ? <p>You can only add and edit users within this client's scope. If you need to add admins, you will need to use Administration view.</p>
          : ""
        : <p>In this administrator view, you can review and manage existing users, including PwC users and Client users. Use the New user button to create new users.</p>
      }
      <div className="flex items-center gap-2 mt-4">
        <div className="shrink">
          <Button kind='primary' icon="icon-plus-outline" onClick={() => setEditVisible(initUser)} disabled={!checkUserLevel(UserLevel.ClientAdmin)}>New user</Button>
        </div>
        <div>
          <Search
            searchType={"secondary"}
            onChange={(value: string) => {
              setSearch(value);
            }}
            searchValue={search}
            className="list-filter"
          />
        </div>
        <div>
          <Select
            data={Array.from(new Set(userData?.map(m => m.userLevel))).map(m => userLevels.find(f => f.value === m?.toString()))}
            placeholder="Filter by role"
            onSelect={(value) => setRoleFilter(value as string[])}
            value={roleFilter}
            multiple={true}
          />
        </div>
        <div>
          <Select
            data={clientData?.map(m => ({ value: m.clientId, label: m.name }))}
            placeholder="Filter by client"
            onSelect={(value) => setClientFilter(value as string[])}
            value={clientFilter}
            multiple={true}
          />
        </div>
      </div>
      <ActiveFilters rows={getData().length} />
      <Table
        originalData={getData()}
        hasTitle
        striped
      >
        <Column field="lastName" sortKey="lastName" renderCell={customizeColumn}>Name</Column>
        <Column field="emailAddress" sortKey="emailAddress" renderCell={customizeColumn}>Email</Column>
        <Column field="userLevel" sortKey="userLevel" renderCell={customizeColumn}>Role</Column>
        <Column field="clientAccess" sortKey="clientAccess" renderCell={customizeColumn}>Client access</Column>
        <Column field="isActive" sortKey="isActive" renderCell={customizeColumn}>Status</Column>
        <Column field="actions" sortKey="actions" renderCell={customizeColumn}>Actions</Column>
      </Table>
      <ConfirmationModal
        visible={deleteVisible !== undefined}
        title="Delete user"
        cancel={() => setDeleteVisible(undefined)}
        confirm={async () => await handleDelete(deleteVisible)}>
        <p>Are you sure you want to delete {deleteVisible?.emailAddress}?</p>
      </ConfirmationModal>
      <Drawer
        initialFocus={false}
        mask={true}
        resizable={true}
        visible={editVisible !== undefined}
        placement="right"
        title={editVisible?.emailAddress ? `Editing ${editVisible?.emailAddress}` : "Add new user"}
        onClose={() => {
          setEditVisible(undefined);
        }}
        style={{ minWidth: 1000 }}
      >
        {editVisible && clientData && <UserForm
          clientData={clientData}
          userData={editVisible || initUser}
          close={() => {
            setEditVisible(undefined);
          }}
        />}
      </Drawer>
    </LoaderWrapper>
  )
}

interface UserFormProps {
  userData?: SystemUser;
  clientData: ClientData[];
  close: () => void;
  tab?: number;
}

const UserForm: FC<UserFormProps> = ({ userData, close, clientData, tab = 0 }) => {
  const { clientId } = useParams();
  const [user, setUser] = useState<SystemUser | undefined>(userData);
  const [index, setIndex] = useState(tab);
  const [domains, setDomains] = useState<string[]>([]);
  const [selectedSystems, setSelectedSystems] = useState<SapSystem[]>();
  const [selectedProcesses, setSelectedProcesses] = useState<{ clientId: string, values: SelectValue }[]>();
  const [errors, setErrors] = useState<(keyof SystemUser)[]>();
  const [formError, setFormError] = useState<string | null>(null);

  const queryClient = useQueryClient();

  const { mutate: addUser, isPending: addingUser } = PostClientUser(clientId);
  const { mutate: updateUser, isPending: updatingUser } = PutClientUser(clientId);

  const parseClaims = (claims: Claim[]) => {
    let systemclaims = claims.filter(f => f.type === ClaimType.SYSTEM)
      .map(m => ({ clientId: m.clientId, systemId: m.systemId }));
    let processValues: { clientId: string, values: SelectValue }[] = [];
    let processClaims = claims.filter(f => f.type > ClaimType.RULES);
    for (let client of Array.from(new Set(processClaims.map(m => m.clientId)))) {
      let values = processClaims.filter(f => f.clientId === client).map(m => m.type);
      processValues.push({ clientId: client, values: values as SelectValue });
    }
    setSelectedProcesses(processValues);
    setSelectedSystems(systemclaims);
  }

  useEffect(() => {
    setUser(userData);
    parseClaims(userData?.claims || []);
  }, [userData])

  const businessProcesses = [
    { value: ClaimType.FIN, label: "FIN", description: "Finance" },
    { value: ClaimType.HR, label: "HR", description: "Human Resources & Payroll" },
    { value: ClaimType.IT, label: "IT", description: "Basis/IT" },
    { value: ClaimType.MMI, label: "MMI", description: "Material Master & Inventory Management" },
    { value: ClaimType.O2C, label: "O2C", description: "Order to Cash" },
    { value: ClaimType.P2P, label: "P2P", description: "Purchase to Pay" },
    { value: ClaimType.PB, label: "PB", description: "Project Business" },
    { value: ClaimType.SB, label: "SB", description: "Service Business" }
  ]
  const emailValidation = (email: string, domains: string[]) => {
    if (domains.length === 0) domains = ["pwc.com"];
    if (!email.includes("@")) return false;
    const emailDomain = email.split('@')[1].trim();
    if (email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/) !== null && domains.includes(emailDomain))
      return true;
    else
      return false;
  }

  const handleUser = (user: SystemUser) => {
    let clientIds = user?.claims?.filter(f => f.type === ClaimType.CLIENT).map(m => m.clientId);
    let tempSystemClaims = selectedSystems?.filter(f => f.clientId && clientIds?.includes(f.clientId)).map(v => ({ clientId: v.clientId, systemId: v.systemId, type: ClaimType.SYSTEM }) as Claim).flat();
    let tempProcessClaims = selectedProcesses?.filter(f => clientIds?.includes(f.clientId)).map(m => Array.isArray(m.values)
      ? m.values.map(v => ({ clientId: m.clientId, type: v as ClaimType }))
      : [{ clientId: m.clientId, type: m.values as ClaimType }]).flat();
    let tempUser = { ...user, claims: [...user.claims?.filter(f => f.type === ClaimType.CLIENT) || [], ...tempProcessClaims || [], ...tempSystemClaims || []] };
    return tempUser;
  }

  const handleSubmit = async () => {
    if (!user) return;
    if (!validateForm()) return;
    let tempUser = handleUser(user);
    tempUser.userLevel = tempUser.userLevel && parseInt(tempUser.userLevel.toString());
    if (tempUser.userId === "new") {
      tempUser.userId = crypto.randomUUID();
      toastMessage("Adding user");
      addUser(tempUser, {
        onSuccess: () => {
          toastMessage("User added successfully");
          queryClient.invalidateQueries({ queryKey: ["clientUsers", clientId] });
          close();
        },
        onError: () => {
          setFormError("Unable to add a user");
        }
      });
    } else {
      toastMessage("Updating user");
      updateUser(tempUser, {
        onSuccess: () => {
          toastMessage("User updated successfully");
          queryClient.invalidateQueries({ queryKey: ["clientUsers", clientId] });
          close();
        },
        onError: () => {
          setFormError("Unable to update user");
        }
      });
    }
  }
  const sectionCheck = () => {
    if (index === 0 && validateForm()) {
      setIndex(1);
    } else if (index === 1 && validateForm()) {
      setIndex(2);
    }
  }
  const validateForm = () => {
    if (!user) return;
    setErrors(undefined);
    let errorList: (keyof SystemUser)[] = [];
    let check = checkFormValues(user, ["firstName", "lastName", "userLevel", "emailAddress"]);
    let domains = clientData.filter(f => user.claims?.filter(f => f.type === ClaimType.CLIENT).map(m => m.clientId).includes(f.clientId)).map(m => m.domains.split(",")).flat();
    if (!emailValidation(user.emailAddress || "", domains))
      check.push("emailAddress");
    if (Array.isArray(check))
      errorList = check;
    if (errorList.length > 0) {
      setErrors(errorList);
      return false;
    }
    setErrors(undefined);
    return true;
  };
  const renderSystemValues = (value: SapSystem[]) => {
    if (Array.isArray(value))
      if (value.length > 0)
        return value.map((v, i) => <Tag
          key={i}
          className="ap-badge-process"
          closable={false}
        >{v.sapNickname}</Tag>);
  }
  const renderProcessValues = (value: SelectValue) => {
    if (Array.isArray(value))
      if (value.length > 0)
        return value.map((v, i) => <Tag
          key={i}
          className="ap-badge-process"
          color={shadeRainbow[i]}
          style={{ color: shadeRainbowText[i] }}
          closable={false}
        >{businessProcesses.find(f => f.value === v)?.label}</Tag>);
  }
  const onTabChange = (index: number) => {
    setIndex(index);
  }
  useEffect(() => {
  }, [errors]);
  return (
    <div className="ap-input-form">
      <Steppers space={316} activeIndex={index} onActiveIndexChange={onTabChange}>
        <Stepper label="User information" status={!errors ? "normal" : "warning"} />
        <Stepper label="Access rights" status="normal" />
        <Stepper label="Summary" status="normal" />
      </Steppers>
      {
        index === 0
          ? (
            <>
              <div className="ap-pattern-form">
                <span className='ap-pattern-form-title'>
                  User information
                </span>
                <span className='ap-pattern-form-required-indicator'>
                  Required Fields
                </span>
              </div>
              <form autoComplete="off">
                <div className="flex gap-2">
                  <div className="flex flex-col grow row-gap-2">
                    <div>
                      <Select
                        required
                        placeholder="User level"
                        data={clientId ? userLevels.filter(f => parseInt(f.value) > 1) : userLevels}
                        value={user?.userLevel?.toString()}
                        suffixTemplate={item => item.description && <span>{item.description}</span>}
                        onSelect={value => {
                          let claims = (user?.userLevel && user?.userLevel > UserLevel.PwCUser) ? user?.claims : [];
                          setUser({ ...user, userLevel: parseInt(value.toString()), claims: claims });
                          setDomains((value as UserLevel) <= UserLevel.PwCUser ? ["pwc.com"] : []);
                        }}
                        itemTemplate={(label: React.ReactNode, item: ItemDataType) => {
                          return (
                            <div>
                              <div style={{ padding: 8, marginRight: 16, display: "block", height: 30 }}><b>{item.label}</b></div>
                              <div style={{ marginLeft: 8, fontSize: 12, display: "block" }}>{item.description.trim()}</div>
                            </div>
                          )
                        }}
                        dropdownRenderMode="portal"
                        error={errors?.includes("userLevel")}
                        errorNode={<ValidationError error="User level is required" />}
                      />
                    </div>
                    {
                      user?.userLevel && user?.userLevel > UserLevel.PwCAdmin
                        ? <div>
                          <Select
                            placeholder="Client"
                            data={clientData.filter(f => clientId ? f.clientId === clientId : true).map(client => ({ value: client.clientId, label: client.name }))}
                            multiple={user?.userLevel && user?.userLevel < UserLevel.ClientAdmin ? true : false}
                            value={user?.claims?.filter(f => f.type === ClaimType.CLIENT).map(m => m.clientId)}
                            disabled={(userData?.userLevel && userData?.userLevel > UserLevel.PwCUser) || false}
                            onSelect={values => {
                              let tempValues = Array.isArray(values)
                                ? values.map(m => ({ clientId: m.toString(), type: ClaimType.CLIENT }))
                                : [({ clientId: values.toString(), type: ClaimType.CLIENT })];
                              setUser({ ...user, claims: tempValues })
                              setDomains(clientData.filter(f => tempValues.map(m => m.clientId).includes(f.clientId)).map(m => m.domains.split(",")).flat())
                            }}
                            valueTemplate={(value: SelectValue, item: ItemDataType | ItemDataType[]) => {
                              return (
                                <>
                                  <div style={{ maxWidth: 400 }}>
                                    {
                                      Array.isArray(item)
                                        ? item.map(m => <Tag key={m.value} closable={false}>{m.label}</Tag>)
                                        : <Tag closable={false}>{item.label}</Tag>
                                    }
                                  </div>
                                </>
                              )
                            }}
                          />
                        </div>
                        : null
                    }
                    <div>
                      <Switch className="ap-field" checked={user?.isActive} showIndicator onChange={(value: boolean) => setUser({ ...user, isActive: value })}>{user?.isActive ? "Active" : "Inactive"}</Switch>
                      <p>If user is marked as inactive, they cannot log on to the service.</p>
                    </div>
                    <div>
                      <Input
                        type="text"
                        value={user?.emailAddress || ""}
                        disabled={userData?.emailAddress}
                        title="Email Address"
                        onChange={(value: string) => setUser({ ...user, emailAddress: value.trim() })}
                        required
                        error={errors?.includes("emailAddress")}
                        errorNode={<ValidationError error="Invalid email address, please check that the domain is on the allowed domains list." />}
                        autoComplete="off"
                      >
                        {domains.length > 0 && <div aria-live="polite" className="ap-field-email-validation-suggestion">Allowed domains: {domains.join(", ")}</div>}
                      </Input>
                    </div>
                    <div>
                      <Input
                        type="text"
                        value={user?.firstName || ""}
                        disabled={user?.userLevel === undefined}
                        title="First name"
                        onChange={(value: string) => setUser({ ...user, firstName: value })}
                        error={errors?.includes("firstName")}
                        errorNode={<ValidationError error="First name is required" />}
                        required
                      />
                    </div>
                    <div>
                      <Input
                        type="text"
                        value={user?.lastName || ""}
                        disabled={user?.userLevel === undefined}
                        title="Last name"
                        onChange={(value: string) => setUser({ ...user, lastName: value })}
                        error={errors?.includes("lastName")}
                        errorNode={<ValidationError error="Last name is required" />}
                        required />
                    </div>
                  </div>
                </div>
              </form>
            </>
          )
          : index === 1
            ? (
              <>
                <div className="ap-pattern-form">
                  <span className='ap-pattern-form-title'>
                    Access rights
                  </span>
                </div>
                {user?.userLevel !== UserLevel.PwCAdmin && (
                  <>
                    {clientData.filter(f => user?.claims?.filter(m => m.type === ClaimType.CLIENT).map(m => m.clientId).includes(f.clientId || "")).map(client => (
                      <div className="ap-userview-client" key={client.clientId}>
                        <h3>{client.name}</h3>
                        <div className="flex flex-col gap-2">
                          <div>
                            <h4>Systems access</h4>
                            <SystemSelect
                              clientId={client.clientId}
                              selected={selectedSystems?.filter(f => f.clientId === client.clientId).map(m => m.systemId) as string[] || []}
                              onSelect={(value) => {
                                setSelectedSystems(selectedSystems?.filter(f => f.clientId !== client.clientId).concat(value) || value)
                              }}
                            />
                            <p>If you do not specify any systems, the user will have <b>access to all</b>.</p>
                          </div>
                          <div>
                            <h4>Business process access</h4>
                            <Combobox
                              multiple
                              placeholder="Business processes"
                              data={businessProcesses}
                              value={selectedProcesses?.find(f => f.clientId === client.clientId)?.values}
                              style={{ marginTop: 16, marginBottom: 16 }}
                              onSelect={(value) => setSelectedProcesses(selectedProcesses?.filter(f => f.clientId !== client.clientId).concat({ clientId: client.clientId, values: value }) || [{ clientId: client.clientId, values: value }])}
                              valueTemplate={renderProcessValues}
                            />
                            <p>If you do not specify any processes, the user will have <b>access to all</b>.</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            )
            : (
              <div className="ap-pattern-form">
                <h1>Summary</h1>
                <p>Check the user details and click save if you are satisfied.</p>
                <div className="flex flex-col gap-2">
                  <div>
                    <h2>User information</h2>
                    <p><b>Name:</b> {user?.lastName}, {user?.firstName}</p>
                    <p><b>Email:</b> {user?.emailAddress}</p>
                    <p><b>Role:</b> {UserLevel[user?.userLevel || 0]}</p>
                    <p><b>Status:</b> {user?.isActive ? "Active" : "Inactive"}</p>
                  </div>
                  <div>
                    <h2>Access rights</h2>
                    {
                      user?.claims?.filter(f => f.type === ClaimType.CLIENT).map(m => (
                        <div key={m.clientId} style={{ marginBottom: 16, marginTop: 24 }}>
                          <h3>{clientData.find(f => f.clientId === m.clientId)?.name}</h3>
                          <b>Systems:</b>
                          <div className="m-4">{renderSystemValues(selectedSystems?.filter(f => f.clientId === m.clientId) || []) || "All systems"}</div>
                          <b>Processes:</b>
                          <div className="m-4">{renderProcessValues(selectedProcesses?.find(f => f.clientId === m.clientId)?.values || []) || "All business processes"}</div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            )
      }
      <FormError error={formError} />
      <div className="ap-footer flex p-2 gap-2">
        <div className="grow">
          {
            index > 0 ?
              <Button
                onClick={() => setIndex(index - 1)}
                kind="primary"
              >back</Button>
              : <Button onClick={close} kind="secondary">Cancel</Button>
          }

        </div>
        <div>
          {index === 2
            ? <Button
              onClick={() => handleSubmit()}
              loading={addingUser || updatingUser}
              kind="primary"
            >Save</Button>
            : <Button
              onClick={sectionCheck}
              kind="primary"
            >Next</Button>
          }

        </div>
      </div>
    </div >
  )
}

const SystemSelect: FC<{ clientId: string, selected: string[], onSelect: (value: SapSystem[]) => void }> = ({ clientId, selected, onSelect }) => {
  const [selectedSystems, setSelectedSystems] = useState<SapSystem[]>();
  const { data: systems, isPending: isSystemPending } = FetchSystems(clientId);

  const renderSystemValues = (value: SelectValue) => {
    if (Array.isArray(value))
      if (value.length > 0)
        return value.map((v, i) => <Tag
          key={i}
          className="ap-badge-process"
          closable={false}
        >{systems?.find(f => f.systemId === v)?.sapNickname}</Tag>);
  }

  const initSelected = useCallback(() => {
    if (!systems) return;
    setSelectedSystems(systems?.filter(f => selected.includes(f.systemId as string)));
    onSelect(systems?.filter(f => selected.includes(f.systemId as string)) || []);
  }, [selected, systems, onSelect]);

  useEffect(() => {
    if (!isSystemPending) {
      initSelected();
    }
  }, [isSystemPending, initSelected]);
  return (
    <Combobox
      multiple
      placeholder="Systems"
      data={systems?.map(m => ({ value: m.systemId, label: m.sapNickname }))}
      style={{ marginTop: 16, marginBottom: 16 }}
      value={selectedSystems?.map(m => m.systemId) as string[] || []}
      valueTemplate={renderSystemValues}
      onSelect={(value) => {
        if (!value) return;
        if (Array.isArray(value)) {
          onSelect(systems?.filter(f => f.systemId && value.includes(f.systemId)) || []);
          setSelectedSystems(systems?.filter(f => f.systemId && value.includes(f.systemId)) || []);
        } else {
          onSelect(systems?.filter(f => f.systemId === value) || []);
          setSelectedSystems(systems?.filter(f => f.systemId === value) || []);
        }
      }}
    />
  )
}
export default UserView;
