import { Button, Badge, Upload, Combobox, FileModel, DropdownButton, Table, Column, Drawer, Select, CalendarPicker } from "@appkit4/react-components";
import { SelectValue } from "@appkit4/react-components/esm/combobox/Combobox";
import { useQueryClient } from "@tanstack/react-query";
import { ConfirmationModal, FormError, LoaderWrapper, LogViewer, ValidationError, toastMessage } from "components/common/helpers";
import { jwtDecode } from "jwt-decode";
import { FetchAnalyses, DeleteAnalysis, PublishAnalysis } from "queries/hooks/administration/analysis";
import { FetchClient } from "queries/hooks/administration/client";
import { FetchRulesets } from "queries/hooks/administration/ruleset";
import { FetchSystems } from "queries/hooks/administration/system";
import { FC, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";
import { uploadData } from "services/api-actions";
import { checkFormValues, getDate, selectFilter } from "services/common";
import { AnalysisData, AnalysisStatus, RuleBag, SapSystem, UserLevel } from "types/analysis";
import { PwCJwt } from "types/common";

type AnalysisList = {
  actions: string;
} & AnalysisData

const AnalysisView: FC = () => {
  const { clientId } = useParams();
  const [visible, setVisible] = useState<string | null>(null);
  const [showPublish, setShowPublish] = useState<AnalysisData | undefined>();
  const [filteredData, setFilteredData] = useState<AnalysisList[]>([]);
  const [deleteVisible, setDeleteVisible] = useState<string | null>(null);
  const [filters, setFilters] = useState<{ date: undefined | Date[], status: SelectValue, system: SelectValue, bag: SelectValue }>({ date: undefined, status: [], system: [], bag: [] });
  const [showAdd, setShowAdd] = useState(false);
  const [cookie] = useCookies();

  const navigate = useNavigate();

  const [showConfirmation, setShowConfirmation] = useState(false);

  const { data: analyses, isPending: isAnalysisPending, error: analysesError } = FetchAnalyses(clientId);

  const { data: systems, isPending: isSystemsPending, error: systemsError } = FetchSystems(clientId);

  const { data: ruleBags, isPending: isBagsPending, error: bagsError } = FetchRulesets(clientId);

  const { data: clientData, isPending: isClientPending, error: clientError } = FetchClient(clientId);

  const { mutate: doDelete } = DeleteAnalysis();

  const filterBags = (select?: SelectValue, data?: AnalysisData) => {
    if (!data?.bag?.bagId || !data) return true;
    if (Array.isArray(select)) {
      if (select.length === 0) return true;
      return select.includes(data.bag?.bagId);
    }
    return true;
  }

  const filterByDates = (data: AnalysisData, dateRange: Date[]) => {
    if (!dateRange || dateRange.length < 2) return true;
    let extractionDate = new Date(data.extractionDate || "");
    if (isNaN(extractionDate.getTime())) return false;
    return (extractionDate >= dateRange[0] && extractionDate <= dateRange[1]);
  }

  const getRuleFilter = () => {
    let bagIds = Array.from(new Set(analyses?.map(m => m.bag?.bagId) || []));
    let bags = [];
    for (let bagId of bagIds) {
      let bag = ruleBags?.find(f => f.bagId === bagId);
      if (bag) {
        bags.push({ value: bag.bagId, label: bag.name });
      }
    }
    return bags;
  }
  const handleDelete = async (analysis: AnalysisData) => {
    if (!analysis) return;
    setDeleteVisible(null);
    await doDelete(analysis, {
      onError: () => {
        toastMessage("Deletion failed", null, "error");
      },
      onSuccess: () => {
        toastMessage("Analysis queued for deletion");
      }
    })
  }
  const checkUserLevel = (level: UserLevel) => {
    const jwtData = jwtDecode<PwCJwt>(cookie["id_token"]);
    return jwtData?.userLevel && UserLevel[jwtData.userLevel as keyof typeof UserLevel] <= level;
  }
  const getClients = () => {
    let clients = [{
      clientId: "00000000-0000-0000-0000-000000000000",
      name: "Default"
    }];
    if (clientData) {
      clients.push({
        clientId: clientData.clientId,
        name: clientData.name
      });
    }
    return clients;
  }
  const resetFilters = () => {
    setFilters({ date: undefined, status: [], system: [], bag: [] });

  }
  const customizeColumn = (row: AnalysisData, field: string) => {
    switch (field) {
      case "analysisDate":
        return getDate(row.analysisDate || "");
      case "extractionDate":
        return (<>{getDate(row[field] || "")}</>);
      case "systemId":
        {
          let system = row.sapSystem;
          if (!system) return "";
          return (<>
            <b>{system?.sapNickname}</b>
            <p>{system?.sapSystemName} <span className="Appkit4-icon icon-arrow-right-small-outline" /> {system?.sapNickname} <span className="Appkit4-icon icon-arrow-right-small-outline" /> {system?.sapClient}</p>
          </>)
        }
      case "bag":
        if (!row.bag) return "";
        return (<>
          <b>{row[field]?.name}</b>
          <p>{row[field]?.description}</p>
        </>);
      case "status":
        switch (row["status"]) {
          case AnalysisStatus.Analyzing:
            return (<Badge value="Processing" type="info-outlined" />);
          case AnalysisStatus.Error:
            return (<Badge value="Error" type="danger" />);
          case AnalysisStatus.Analysed:
            return (<Badge value="Analysed" type="primary" />);
          case AnalysisStatus.Published:
            return (<Badge value="Published" type="success" />);
          case AnalysisStatus.Deleting:
            return (<Badge value="Queued for deletion" type="danger" />);
        }
        break;
      case "actions":
        return (
          <DropdownButton
            compact
            splitButton
            kind="tertiary"
            data={[
              { label: "View analysis", value: 1 },
              { label: "View analysis log", value: 2, disabled: !checkUserLevel(UserLevel.PwCAdmin) },
              { label: "Publish", value: 3, disabled: row["status"] !== 2 || !checkUserLevel(UserLevel.PwCAdmin) },
              { label: "Delete analysis", value: 4, disabled: !checkUserLevel(UserLevel.PwCAdmin) }
            ]}
            onSelect={(value) => {
              switch (value) {
                case 1:
                  navigate(`/analysis/${row["analysisId"]}`);
                  break;
                case 2:
                  setVisible(row["analysisId"] || null);
                  break;
                case 3:
                  setShowPublish(row);
                  break;
                case 4:
                  setDeleteVisible(row['analysisId'] || null);
                  break;
                default:
                  break;
              }
            }}
            onClick={() => navigate(`/analysis/${row["analysisId"]}`)}
          >
            View
          </DropdownButton>
        );
      default:
        return row[field as keyof AnalysisData];
    }
  }
  const checkIfFilterHasValues = () => {
    return (filters.date && filters.date.length > 0) || (filters.status as string[]).length > 0 || (filters.system as string[]).length > 0 || (filters.bag as string[]).length > 0;
  }
  useEffect(() => {
    setFilteredData(analyses?.filter(f => selectFilter(f, 'status', filters.status))
      .filter(f => filterByDates(f, filters.date || []))
      .filter(f => selectFilter(f, 'systemId', filters.system))
      .filter(f => filterBags(filters.bag, f))
      .map(m => ({ ...m, actions: "" })) || []);
  }, [filters, analyses]);
  return (
    <LoaderWrapper loading={[isAnalysisPending, isBagsPending, isSystemsPending, isClientPending]} errors={[analysesError, bagsError, systemsError, clientError]}>
      <h3>Analyses</h3>
      {
        checkUserLevel(UserLevel.PwCAdmin) && (<p>A view for creating new analyses and reviewing existing analyses.</p>)
      }
      <div className="flex items-center gap-4 mt-4 mb-4">
        {
          checkUserLevel(UserLevel.PwCAdmin) && (<div className="shrink">
            <Button kind='primary' icon="icon-plus-outline" onClick={() => setShowAdd(true)}>New analysis</Button>
          </div>)
        }
        <div className="pr-16">
          <CalendarPicker
            selectRange
            value={filters.date}
            placeholder="Filter by extraction date"
            className="list-filter"
            dateSeparator="."
            format="DD.MM.YYYY"
            viewStart="year"
            viewEnd="decade"
            onChange={(values: Date[]) => setFilters({ ...filters, date: values })}
          />
        </div>
        <div>
          <Select
            placeholder="Filter by status"
            className="list-filter"
            value={filters.status}
            multiple
            data={Array.from(new Set(analyses?.map(m => m.status)) || []).map(m => ({ value: m, label: AnalysisStatus[m as AnalysisStatus] }))}
            onSelect={(value) => setFilters({ ...filters, status: value })}
          />
        </div>
        <div>
          <Select
            placeholder="Filter by system"
            className="list-filter"
            value={filters.system}
            multiple
            data={systems?.map(m => ({ value: m.systemId, label: m.sapNickname }))}
            onSelect={(value) => setFilters({ ...filters, system: value })}
          />
        </div>
        <div>
          <Select
            placeholder="Filter by rule set"
            className="list-filter"
            value={filters.bag}
            multiple
            data={
              getRuleFilter()
            }
            onSelect={(value) => setFilters({ ...filters, bag: value })}
          />
        </div>
        {
          checkIfFilterHasValues() &&
          <div>
            <Button kind="secondary" onClick={resetFilters}>Clear</Button>
          </div>
        }
      </div>
      <LoaderWrapper loading={[isAnalysisPending]} errors={[analysesError]} inline>
        {
          filteredData.length > 0
            ? <Table
              originalData={filteredData}
              hasTitle
              striped
            >
              <Column field="analysisId" renderCell={(row) => <Link to={`/analysis/${row.analysisId}`}><span className="Appkit4-icon icon-hyperlink-fill"></span> View analysis</Link>}></Column>
              <Column field="analysisDate" sortKey="analysisDate" renderCell={customizeColumn}>Analysis date</Column>
              <Column field="extractionDate" sortKey="extractionDate" renderCell={customizeColumn}>Extraction date</Column>
              <Column field="bag" sortKey="bag" renderCell={customizeColumn}>Rule set</Column>
              <Column field="systemId" sortKey="systemId" renderCell={customizeColumn}>System</Column>
              <Column field="status" sortKey="status" renderCell={customizeColumn}>Status</Column>
              <Column field="actions" renderCell={customizeColumn}>Actions</Column>
            </Table>
            : <p>No analyses found</p>
        }
      </LoaderWrapper>
      <Drawer
        initialFocus={false}
        mask={true}
        resizable={true}
        footer={
          <>
            <Button onClick={() => setVisible(null)}>Ok</Button>
          </>
        }
        visible={visible !== null}
        placement="right"
        title="Log"
        onClose={() => setVisible(null)}
      >
        {visible && <LogViewer analysisId={visible || ""} />}
      </Drawer>
      <ConfirmationModal
        visible={deleteVisible !== null}
        title="Delete analysis"
        cancel={() => setDeleteVisible(null)}
        confirm={async () => (deleteVisible && analyses) && await handleDelete(analyses.filter(f => f.analysisId === deleteVisible)[0])}>
        <>
          <p>Are you sure you want to delete this analysis?</p>
          <p>Analysis will be queued for deletion, and will be gone within the next 24 hours.</p>
        </>
      </ConfirmationModal>
      {
        <Drawer
          initialFocus={false}
          mask={true}
          resizable={true}
          style={{ minWidth: "800px" }}
          visible={showAdd}
          placement="right"
          title="Add new analysis"
          onClose={() => setShowAdd(false)}
        >
          <AnalysisForm
            systems={systems || []}
            ruleBags={ruleBags || []}
            cancel={() => {
              setShowAdd(false);
            }}
            confirmed={() => {
              setShowAdd(false);
              setShowConfirmation(true);
            }}
            clients={getClients()}
          />
        </Drawer>
      }
      <ConfirmationModal
        visible={showConfirmation}
        title="Analysis created"
        cancel={() => {
          setShowConfirmation(false);
        }}
        confirm={() => {
          setShowConfirmation(false);
        }}
      >
        <p>Analysis has been created and is now being processed.</p>
        <p>It will appear on the list of analyses within the next 15 minutes. You can follow the progress via log view.</p>
      </ConfirmationModal>
      <PublishForm analysis={showPublish} close={() => setShowPublish(undefined)} />
    </LoaderWrapper>
  )
}

const PublishForm: FC<{ analysis: AnalysisData | undefined, close: () => void }> = ({ analysis, close }) => {
  const { clientId } = useParams();
  const queryClient = useQueryClient();
  const { mutate: publishAnalysis } = PublishAnalysis(analysis);

  const handleSubmit = async () => {
    if (!analysis) return;
    await publishAnalysis(analysis, {
      onError: () => {
        toastMessage("Publishing failed", null, "error");
      },
      onSuccess: () => {
        toastMessage("Analysis published");
        queryClient.invalidateQueries({ queryKey: ["analyses", clientId] });
        close();
      }
    });
  }
  return (
    <ConfirmationModal
      visible={analysis !== undefined}
      title="Publish analysis"
      cancel={() => close()}
      confirm={() => handleSubmit()}
    >
      <p>Are you sure you want to publish this analysis?</p>
      <p>Once published, the analysis will be available for the client to view.</p>
    </ConfirmationModal>
  )
}

interface AnalysisFormProps {
  systems: SapSystem[];
  ruleBags: RuleBag[];
  cancel: () => void;
  confirmed: () => void;
  clients: {
    name: string, clientId: string
  }[];
}
type AnalysisFormType = {
  system?: SelectValue;
  ruleset?: SelectValue;
  file?: FileModel;
}

const AnalysisForm: FC<AnalysisFormProps> = ({ cancel, ruleBags, systems, clients, confirmed }) => {
  const { clientId } = useParams();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [analysis, setAnalysis] = useState<AnalysisFormType>();
  const [errors, setErrors] = useState<(keyof AnalysisFormType)[] | undefined>();

  const handleSubmit = async (file: FileModel, system: string, bag: string) => {
    if (!clientId) return false;
    if (!validateForm()) return false;
    if (!analysis?.file || !analysis.ruleset || !analysis.system) return false;
    setLoading(true);
    let payload = new FormData();
    payload.append("file", analysis.file.originFile as Blob, analysis?.file.name);
    payload.append("systemId", analysis.system.toString());
    payload.append("bagId", analysis.ruleset.toString());
    payload.append("clientId", clientId);
    let request = await uploadData(`client/${clientId}/analysis`, payload, (progress) => console.log(progress));
    if (!request.isError) {
      confirmed();
      setLoading(false);
    }
    setError("Unable to create new analysis, please contact support if the issue persists");
    setLoading(false);
  }
  const validateForm = () => {
    if (!analysis) return;
    setErrors(undefined);
    let errorList: (keyof AnalysisFormType)[] = [];
    let check = checkFormValues(analysis, ["system", "ruleset"]);
    if (!analysis.file)
      check.push("file");
    if (Array.isArray(check))
      errorList = check;
    if (errorList.length > 0) {
      setErrors(errorList);
      return false;
    }
    setErrors(undefined);
    return true;
  }
  const fileChange = (file: FileModel, files: FileList) => {
    setAnalysis({ ...analysis, file: file });
  }
  const getRuleSets = () => {
    let data = [];
    for (let client of clients) {
      data.push({
        label: client.name,
        type: "group",
        children: ruleBags.filter(f => f.clientId === client.clientId).map(m => ({ value: m.bagId, label: m.name, description: m.description }))
      });
    }
    return data;
  }
  return (
    <div className="ap-input-form">
      <FormError error={error} />
      <form autoComplete="off">
        <div className="flex flex-col gap-4">
          <div>
            <div className="ap-pattern-form">
              <span className='ap-pattern-form-title'>
                Basic information
              </span>
              <span className='ap-pattern-form-required-indicator'>
                Required Fields
              </span>
            </div>
          </div>
          <div>
            <Combobox
              data={systems.map(m => ({ value: m.systemId, label: m.sapNickname }))}
              onSelect={(value) => setAnalysis({ ...analysis, system: value })}
              value={analysis?.system}
              placeholder="System"
              dropdownRenderMode="portal"
              required
              suffixTemplate={item => {
                return (item.description && <span>{item.description}</span>)
              }}
              error={errors?.includes("system")}
              errorNode={<ValidationError error="System is required" />}
            />
          </div>
          <div>
            <Combobox
              data={getRuleSets()}
              onSelect={(value) => setAnalysis({ ...analysis, ruleset: value })}
              value={analysis?.ruleset}
              required
              placeholder="Rule set"
              dropdownRenderMode="portal"
              suffixTemplate={item => {
                return (item.description && <span>{item.description}</span>)
              }}
              error={errors?.includes("ruleset")}
              errorNode={<ValidationError error="Rule set is required" />}
              labelKey="label"
            />
          </div>
          <div>
            <h3>File</h3>
            <Upload
              onChange={fileChange}
              autoUpload={false}
              multiple={false}
              acceptFileType=".zip"
              uploadTitle="Upload extracted data"
              uploadInstruction="You can only upload files that are in PwC Extract format. Otherwise the analysis process will fail."
            />
            {errors?.includes("file") && <ValidationError error="File is required" />}
          </div>
        </div>
      </form>
      <div className="ap-footer flex gap-4">
        <div className="grow">
          <Button onClick={cancel} kind="secondary">Cancel</Button>
        </div>
        <div>
          <Button
            onClick={handleSubmit}
            kind="primary"
            loading={loading}
          >Save</Button>
        </div>
      </div>
    </div>
  )
}

export default AnalysisView;