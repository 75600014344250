import { Breadcrumb, BreadcrumbItem, Panel, Tab, Tabs } from '@appkit4/react-components';
import ClientTemplate from 'components/layout/clientTemplate';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserLevel } from 'types/analysis';
import AnalysisView from './analysisView';
import SystemView from './systemView';
import UserView from './userView';
import 'style/form.scss';
import { jwtDecode } from 'jwt-decode';
import { useCookies } from 'react-cookie';
import { PwCJwt } from 'types/common';
import { FetchClient } from 'queries/hooks/administration/client';
import { LoaderWrapper } from 'components/common/helpers';


const Client: FC<{ index: number }> = ({ index }) => {
  const { clientId } = useParams();
  const navigate = useNavigate();

  const [cookie] = useCookies();

  const checkUserLevel = (level: UserLevel) => {
    const jwtData = jwtDecode<PwCJwt>(cookie["id_token"]);
    return jwtData?.userLevel && UserLevel[jwtData.userLevel as keyof typeof UserLevel] <= level;
  }

  const { data: clientData, isPending, error } = FetchClient(clientId);

  const onTabChange = (index: number) => {
    switch (index) {
      case 0:
        navigate(`/client/${clientId}/analyses`);
        break;
      case 1:
        navigate(`/client/${clientId}/systems`);
        break;
      case 2:
        navigate(`/client/${clientId}/users`);
        break;
    }
  }

  const getView = (index: number) => {
    switch (index) {
      case 0:
        return (
          <AnalysisView />
        )
      case 1:
        return (
          <SystemView />
        )
      case 2:
        return (
          <UserView />
        )
    }
  }
  return (
    <LoaderWrapper loading={[isPending]} errors={[error]}> 
        <ClientTemplate>
          <Breadcrumb>
            <BreadcrumbItem>{clientData?.name}</BreadcrumbItem>
            <BreadcrumbItem>{index === 0 ? "Analyses" : index === 1 ? "Systems" : "Users"}</BreadcrumbItem>
          </Breadcrumb>
          <h1>{!clientId ? "Administration" : clientData?.name}</h1>
          <Tabs type="underline" activeIndex={index} onTabChange={onTabChange}>
            <Tab label="Analyses" value="analyses" />
            <Tab label="Systems" value="systems" />
            <Tab label="Users" value="users" disabled={!checkUserLevel(UserLevel.ClientAdmin)} />
          </Tabs>
          <Panel style={{ paddingTop: 8 }}>
            {
              getView(index)
            }
          </Panel>
        </ClientTemplate>
        </LoaderWrapper>
      )
}

export default Client;