import PageFooter from "components/layout/pageFooter";
import { FC } from "react";

const TermsAndConditions: FC = () => (
  <div className="w-full grid grid-cols-3 h-screen">
    <div className="col-span-2">
      <div className="login-logo" />
      <div className="p-8 mt-32 ml-32">
        <h1>Insights Access Risks service SaaS terms</h1>
        <ol className="terms">
          <li><h3>Introduction</h3></li>
          <ol className="terms">
            <li>
              Contract - These SaaS Terms apply to the provision of Software by PwC to Client under the Contract, as described in the Cover Sheet.Capitalised terms are defined in the Cover Sheet and clause 14. of these SaaS Terms.
            </li>
            <li>
              Precedence - In the event of conflict between the Cover Sheet and these terms, the relevant terms of the Cover Sheet will prevail.
            </li>
          </ol>
          <li><h3>Software and services</h3></li>
          <ol className="terms">
            <li>
              Ownership of the software - PwC (or its licensors) owns all right, title, and interest, including all Intellectual Property Rights, in and to the Software and any Documentation or other materials provided by PwC under the Contract.
            </li>
            <li>
              Grant of rights - PwC grants to Client a limited, non-exclusive, non-transferable right for Client and its End Users to access and use the Software during the Term, solely in accordance with the Documentation and this Contract. Client and its End Users may only access and use the Software through the URL designated by PwC and only with valid Software Accounts granted to Client by PwC. Client has no right to create derivative works of the Software.Client acknowledges that this Contract shall not be construed as granting or conferring, either expressly or implicitly, any Intellectual Property Rights belonging to PwC, the ownership of which will always remain under the sole and exclusive ownership of PwC. Furthermore, Client acknowledges that this Contract in no way limits PwC's (or its licensors) right to license the Software or Intellectual Property Rights to any other party, or otherwise restrict any other rights in the Software that PwC might have.
            </li>
            <li>
              Hosting - During the Term, PwC will host, maintain and make available the Software to Client and its End Users pursuant to the Contract. To the extent PwC provides details of availability targets and other service levels that might apply to the Software from time to time, the targets or service levels are subject to change and not contractually binding. Any targets or service levels: (a) refer to access to the Software over the Internet; and (b) exclude any periods of downtime or suspension described in clause 10.
            </li>
            <li>
              Support and maintenance - PwC will provide support and maintenance services for the Software as described in the Cover Sheet, which may include updates to the Software to address error corrections, legal or regulatory issues, and/or urgent security or performance related issues. If PwC makes a material change to the Software, PwC will inform the Client.
            </li>
            <li>
              Ancillary services - Any services associated with the Software are set out in and governed by the terms of the engagement letter dated XX.XX.20XX.
            </li>
            <li>
              Retention - Client may request that PwC delete Client Materials that are stored on the Software. PwC will use commercially reasonable efforts to comply with such request, and Client will reimburse PwC for any associated costs and expenses incurred by PwC. Client acknowledges and agrees, however, that some residual Client Materials may remain in back-up files, deletion logs and similar materials despite deletion efforts.
            </li>
            <li>
              Open source software - The Software may include certain free and/oropen source software components as may be identified on the Cover Sheet (together, “Open Source Software”). Client's use of Open Source Software is governed exclusively by the terms applicable to the Open Source Software and not the terms of the Contract. Client acknowledges any access to Open Source Software is provided on an “as is” basis.
            </li>
            <li>
              Facilities - All facilities used to host the Software will adhere to reasonable security standards no less protective than the security standards at facilities where PwC hosts and stores its own data and software of a similar type.For the purposes of this clause, Software will be construed to include associated Client Materials.PwC has implemented at least industry standard systems and procedures designed to (a) ensure the security and confidentiality of the Software and (b) protect against anticipated threats or hazards to the security or integrity of the Software.
            </li>
            <li>
              Data location - PwC may host the Software, including Client Materials,anywhere PwC or PwC Subcontractors maintain facilities, unless otherwise agreed by the parties in the Cover Sheet.
            </li>
            <li>
              Anti-virus - PwC will use standard, commercially-available virus-checking software in relation to any data, files and output accessible using the Software.
            </li>
            <li>
              Feedback - If Client chooses to provide comments or feedback to PwC in relation to the Software, Client hereby grants to PwC a worldwide, nonexclusive, royalty-free, transferable, sublicensable, perpetual and irrevocable license to use and otherwise exploit the Feedback for any purpose.
            </li>
          </ol>
          <li>
            <h3>Client responsibilities</h3>
          </li>
          <ol className="terms">
            <li>
              End users
              <ol className="alphabetical">
                <li>
                  Client is responsible for itsownand its End Users' use of the Software, including compliance with the Contract.
                </li>
                <li>
                  Client will ensure that only End Users access the Software.
                </li>
                <li>
                  Client will promptly notify PwC in writing if (i) any changes to the End Users' access rights or permissions are required; and/or (ii) Client becomes aware of any unauthorised use of the Software.
                </li>
                <li>
                  Client may not licence or transfer its rights in relation to the Software except as permitted under clause 13.4.
                </li>
              </ol>
            </li>
            <li>
              Acceptable use - Client will not and will ensure that End Users will not:
              <ol className="alphabetical">
                <li>
                  infringe PwC's or its licensors'    Intellectual Property Rights;
                </li>
                <li>
                  decompile, decipher, disassemble, reverse engineer or otherwise decrypt the Software except to the extent permitted by non-excludable laws;
                </li>
                <li>
                  use the Software to provide services to a third party or allow any third party to either obtain a copy of the Software or to access or use the Software in any way not expressly agreed in writing by PwC in advance;
                </li>
                <li>
                  integrate or inter-operate the Software with other software, hardware or data used or licensed by Client or the Software without obtaining any necessary permits, consents or licences required; or
                </li>
                <li>
                  duplicate, modify or create a derivative worksfrom the Software without PwC's prior written consent.
                </li>
              </ol>
            </li>
            <li>
              Client materials - Client will ensure that it has the necessary rightsand/or permitsto provide the Client Materials to PwC and to grant the rights and licenses granted to PwC under the Contract. Client acknowledges that the Software is not intended to hold or maintain master or original information and agrees that Client will be solely responsible for maintaining a backup of all Client Materials.
            </li>
            <li>
              Information about use - Upon PwC's reasonable request, Client will allow PwC (or PwC Subcontractors) to undertake an audit or inspection for the purposes of ascertaining whether Client is in compliance with this Contract. Client agrees to facilitate such audit or inspection by making available relevant records evidencing its use of the Software and by directing its personnel to cooperate with PwC.
            </li>
          </ol>
          <li>
            <h3>Fees</h3>
          </li>
          <ol className="terms">
            <li>
              Payment method - Client will pay PwC fees as described in the Cover Sheet. Except as set out in clause 7.2(c), all fees paid are non-refundable. Unless otherwise stated in the Cover Sheet, PwC may vary its fees in each year of the Contract.
            </li>
            <li>
              Taxes - Client will pay any taxes, including sales, use or value added tax arising from, or levied on PwC in relation to, the Software (excluding taxes on PwC's income).
            </li>
          </ol>
          <li><h3>Confidentiality</h3></li>
          <ol className="terms">
            <li>
              Confidential information - Each party agrees to use the other's Confidential Information only in relation to the performance of the Contract, and not to disclose it, except where required by law or regulation or by a professional body of which PwC is a member. PwC may give Confidential Information to the PwC Subcontractors and to others involved in performance of the Contract or the Services, provided they are subject to confidentiality obligations.PwC can also perform services for the client's competitors and other parties whose interest may conflict with the client's interests. PwC shall not disclose the client's confidential information in that case, either.
            </li>
            <li>
              Return of confidential information - Except to the extent necessary for a party to continue to exercise its rights and perform its obligations under the Contract or as required by applicable law or professional standard, at any time upon the disclosing party's request, a receiving party will delete or destroy documents and other materials that contain or constitute Confidential Information of the disclosing party, as directed by the disclosing party.
            </li>
            <li>
              Data use - notwithstanding other provisions in this Contract and provided that PwC complies with its confidentiality obligations, Client agrees that PwC may use any information obtained from Client's use of the Software or supplied by Client or anyone else working with or for Client for the purposes of improving the Software and PwC's services.
            </li>
          </ol>
          <li>
            <h3>Data Protection</h3>
          </li>
          <ol className="terms">
            <li>
              Compliance - Each party will comply with applicable data protection legislation.
            </li>
            <li>
              Providing personal data - Client will not provide PwC with personal data unless the personal data is required for the performance of the Contract. In respect of any personal data shared with PwC, Client confirms it has the necessary authority for PwC to use and transfer it in accordance with the Contract, and that data subjects have been given required information regarding its use.
            </li>
            <li>
              Permitted processing- Each party may process personal data for any of the following purposes: (a) performing the Contract; (b) security, quality and risk management activities; (c) complying with any requirement of law, regulation or a professional body of which it is a member; (d) administering, managing and developing its business and services; and (e) providing information about itself and its range of services. Additional information about how a party uses personal data may be provided in     the privacy statement at https://www.pwc.fi/en/privacy/privacy-policy.html.
            </li>
            <li>
              Permitted transfers - Each party may transfer personal data shared with it to any of its Affiliates (if Client) or PwC Subcontractors (if PwC) in relation to any of the purposes set out in this clause 6. Some of these recipients may be located outside the country where the personal data originated or the data subjects are located. Each party may transfer the personal data only where it has a lawful basis to do so and any appropriate contractual or comparable safeguards required by applicable data protection legislation are in place to protect the personal data being transferred.
            </li>
            <li>
              Data processing services- To the extent Client engages PwC to process personal data on Client's behalf, i.e., as a processor under the EU General Data Protection Regulation 2016/679, PwC will:
              <ol className="alphabetical">
                <li>
                  process it only on Client's documented instructions (which include any processing permitted under the Contract);
                </li>
                <li>
                  implement appropriate measures designed to ensure its security, including imposing confidentiality obligations on relevant personnel;
                </li>
                <li>
                  transfer it only to sub-processors (permitted under the Contract or as notified to Client or set out in PwC's privacy statement) under a written contract that imposes obligations consistent with those in this clause 6.5;
                </li>
                <li>
                  provide Client with commercially reasonable assistance in carrying out any legally required data protection impact assessments, complying with the rights of data subjects and complying with Client's own data security obligations under applicable data protection legislation;
                </li>
                <li>
                  notify Client without undue delay after becoming aware of a breach of security that led to its accidental or unlawful destruction, loss or alteration or to its unauthorised disclosure;
                </li>
                <li>
                  on Client's request, either return or destroy personal data when the Contract ends;
                </li>
                <li>
                  on Client's written request, provide Client with reasonable information necessary to demonstrate PwC's compliance with this clause 6.5, which may include any available third party security reports; and
                </li>
                <li>
                  where required by applicable data protection legislation, inform Client immediately if it thinks Client's instructions infringe applicable data protection legislation.
                </li>
              </ol>
            </li>
          </ol>
          <li>
            <h3>Intellectual property indemnity</h3>
          </li>
          <ol className="terms">
            <li>
              Indemnity - PwC will defend any claims against Clientin Finlandalleging that the Software infringes a third party's Intellectual Property Right (“IPR Claim”) and indemnify Client for anyfinal and enforceablejudgements awarded against Client arising from an IPR Claim or any final settlement approved by PwC.
            </li>
            <li>
              Potential claim - When PwC becomes aware of any actual or potential IPR Claim, it may at its sole discretion: (a) modify or replace any part of the Software with functionally equivalent features and performance; (b) obtain rights for Client to continue using the Software; or (c) terminate the Contract and issue a refund for the fees (if any) paid for the Software for the remainder of the Term. Client agrees to abide by PwC's decision and, if directed by PwC, to stop using the Software.
            </li>
            <li>
              IPR Claims procedure -This indemnity is conditional on Client (a) having complied with and continuing to comply with the Contract; (b) notifying PwC promptly of any actual or threatened IPR Claim; (c) giving PwC control of the defense and settlement of the IPR Claim; (d) complying (at PwC's expense) with all of PwC's reasonable requests in defending or settling the IPR Claim; (e) taking all reasonable steps to mitigate the amount of Client's loss; and (f) without PwC's prior written consent, not making any admission or acting in a way that may be prejudicial to any litigation or negotiation of the IPR Claim.
            </li>
            <li>
              Exclusions - This indemnity does not cover IPR Claims arising from (a) the combination of the Software with products or services not provided by PwC; (b) modification of or work performed on the Software by any person other than PwC; (c) modifications to the Software made at Client's direction; or (d) Client's breach of the Contract.
            </li>
          </ol>
          <li><h3>Liability</h3></li>
          <ol className="terms">
            <li>
              Exclusions of loss - PwC is not liable for losses due to unauthorised use of the Software, data or associated systems, or losses that can be traced back to circumstances or actions caused by the Client or any third parties. To the fullest extent allowed by applicable law, in no event will PwCbe liable for (a) loss or corruption of ClientMaterialsor otherdata; (b) loss of profits, goodwill, business opportunity, anticipated savings or benefits; or (c) indirect, punitive, special, exemplary or consequential loss or damages.
            </li>
            <li>
              Liability cap - Subject to clause 8.3, PwC's total liability for all claims relating to the Software and the Contract is limited to the amount of fees paid under the Contract during the 12 months preceding the date on which the claim arose.
            </li>
            <li>
              Unlimited liability - Irrespective ofclause 8.2, nothing in the Contract limits a party's liability for (a) fraud,(b) Client's breach of clause 3, (c) IPR Claims under clause 7, or (d) anything else that may not be limited or excluded by law.
            </li>
            <li>
              Service Level - If the Cover Sheet stipulates the inclusion of a service level agreement ("SLA"), Client's sole redress against Software downtime (including delays or faults) will be financial compensation as regulated in such SLA.
            </li>
            <li>
              Restriction on claims - PwC partners, principals, and employees, and PwC Subcontractors (collectively, “Beneficiaries”), have no liability or obligations arising out of the Contract. Client agrees to (a) bring any claim arising from the Software against PwC and not against the Beneficiaries; and (b) ensure that its Affiliates do not assert any claim against PwC or the Beneficiaries. If any Affiliates receive Software under the Contract, Client will provide a copy of the Contract to such Affiliates, inform them that the delivery of the Software is governed by the terms of the Contract (including the liability limitations herein), and Client's Affiliates should notify Client of any disputes or potential claims arising from the Software. Client is liable for any claims brought by its Affiliates against Beneficiaries in contravention of this clause.
            </li>
            <li>
              Client affiliates - Client will ensure that its Affiliates comply with the Contract as if they were the Client, and Client assumes responsibility and liability for such Affiliates' acts and omissions including their compliance with the Contract.
            </li>
            <li>
              Interdependence - PwC will not be responsible for any delay or loss that arises because Client or any third party fails to cooperate with PwC or fulfillits obligations under the Contract.
            </li>
            <li>
              Client Idemnity - Client will defend and indemnify PwC and PwC Subcontractors in any third party claim to the extent arising from Client's or End Users' use of the Software or breach of the terms of the Contract.
            </li>
          </ol>
          <li>
            <h3>Warranty</h3>
          </li>
          <ol className="terms">
            <li>
              PwC warranties - PwC warrants that the Software, in the form provided to Client and when accessed and used in accordance with the Contract, will function in all material respects as set forth in the Documentation. PwC does not warrant that the Software or its outputs will be free from viruses or other harmful components. In this clause, “Software” excludes any data, software and other content and materials that are ownedor providedby any third party and included in the Software.
            </li>
            <li>
              Exclusion of warranties - To the fullest extent permitted by law, no warranties, conditions or other terms are implied under this Contract. PwC does not warrant that the Software will: (a) be fit for any particular purpose; (b) be free of errors or defects, or operate securely, continuously or in an uninterrupted manner; (c) result in Client's compliance with laws, rules or regulations of any government or regulatory authority; or (d) meet Client's needs.
            </li>
            <li>
              Client responsibility - Client accepts responsibility for verifying the accuracy of the data input to the Software.
            </li>
          </ol>
          <li>
            <h3>Suspension</h3>
            PwC may suspend the use of or access to the Software from time to time:
            <ol className="alphabetical">
              <li>
                to perform routine or emergency maintenance;
              </li>
              <li>
                implement service changes and upgrades to the Software;
              </li>
              <li>
                if PwC reasonably believes that Client's or any End User's use of the Software could adversely impact other clients' or their end users' use of the Software or the hosting environment, such as the servers used to provide the Software;
              </li>
              <li>
                if there is suspected unauthorised third party access to the Software;
              </li>
              <li>
                if PwC reasonably believes that    suspension is required to comply with applicable law;
              </li>
              <li>
                if PwC becomes aware of any actual or potential IPR Claim;
              </li>
              <li>
                to mitigate issues caused by any acts or omissions of third parties or issues with any internet infrastructure; or
              </li>
              <li>
                if Client is in breach of the Contract, or if the Software is, in PwC's opinion, being misused.
              </li>
            </ol>
            Any such suspension or resulting downtime will be limited to the minimum extent necessary in the circumstances, as determined by PwC.
          </li>
          <li><h3>Term and Termination</h3></li>
          <ol className="terms">
            <li>
              Term - The Contract will commence on the Effective Date specified in the Cover Sheet and will last for the Term specified in the Cover Sheet, unless it is terminated earlier in accordance with the Contract's terms (the “Term”).
            </li>
            <li>
              Termination by either party - Either party may terminate the Contract by providing written notice to the other party if:
            </li>
            <li>
              the other party materially breaches its obligations under the Contract and does not cure such breach within 14 days after receipt of written notice of the breach;
            </li>
            <li>
              the other party is or appears likely to be unable to pay its debts or becomes insolvent; or
            </li>
            <li>
              the performance of the Contract (including the application of any fee arrangements) may breach independence restrictions that apply or arise with respect to PwC's relationship with Client or a legal or regulatory requirement or standard.
            </li>
            <li>
              Termination forconvenience- Client may terminate the Contract for its convenience on 90 days' prior written notice to PwC, provided that Client, within five (5) days after the effective date of termination, pays PwC any fees that would otherwise have been due for the remainder of the Term (as if the Contract had not been terminated).PwC may terminate the Contract for its convenience on 90 days' prior written notice to the client.
            </li>
            <li>
              Termination by PwC - PwC may terminate the Contract if Client or any End User fails to cooperate with a reasonable audit or inspection by PwC of any suspected violation of the Contract.If the Software is no longer to be offered by PwC, delivery may be suspended by giving a 90 day notice.
            </li>
            <li>
              Effect of termination - Client will pay to PwC all fees then due and owing in accordance with the Contract as at the date of termination or expiration. Upon expiration or termination of the Contract, Client and its End Users will immediately cease accessing and using the Software, and PwC may disable access and use with immediate effect. Subject to any applicable law, PwC may destroy or otherwise dispose of any of Client's Materialsor data that PwC has in its possession.
            </li>
          </ol>
          <li>
            <h3>Dispute Resolution</h3>
            Any disputes arising from the Contract shall be solved finally by arbitration in accordance with the Arbitration Rules of the Finland Chamber of Commerce. The arbitration tribunal shall comprise of three (3) members unless otherwise agreed by the parties. The place of arbitration shall be Helsinki. The language of the arbitration proceedings shall be Finnish, unless the parties agree on using Swedish or English as the language of the pings. If the matter concerns the collection of PwC's undisputed receivables, PwC can submit the case to be resolved by the District Court of Helsinki. Any Claims by Client in relation to the Services or the Contract must be brought no later than one year from the date Client became aware or reasonably should have become aware of the facts which give rise to the alleged Claim.
          </li>
          <li><h3>General</h3></li>
          <ol className="terms">
            <li>
              Force majeure -Any delay in performance caused by fire, flood, earthquake, elements of nature or acts of God, epidemics, pandemics, wars, terrorist acts, site-specific terrorist threats, riots, civil disorders, rebellions or revolutions, government orders, network communications failure or delay, or any other similar cause beyond the reasonable control of a party is not a breach of the Contractfor so long as such conditions prevail and the obligated party continues to use reasonable efforts to mitigate the force majeure event.
            </li>
            <li>
              Entire agreement and severability - The Contract forms the entire Contract between PwC and Client in relation to the Software and replaces any earlier written or oral contracts, representations or discussions. If any provision is found to be unenforceable, the remainder of the Contract will be enforced to the fullest extent possible.
            </li>
            <li>
              PwC subcontractors - PwC may use PwC Subcontractors to perform the obligations of the Contract and for the other purposes set out in clauses 5 and 6 of these terms. PwC is solely responsible for the Software provided by the PwC Subcontractors.
            </li>
            <li>
              Assignment - No party may assign or delegate its rights or obligations under the Contract without the other party's prior written consent, and any assignment or delegation without such consent is void.
            </li>
            <li>
              Publicity - PwC may refer to Client for marketing purposes (for example as a client and user of the Software) as long as PwC does not disclose Client Confidential Information. Except as expressly set out in the Contract, Client does not and will not acquire any right under the Contract to use, and will not use, the name “PricewaterhouseCoopers” or “PwC” (either alone or in conjunction with or as part of any other word or name) or any logos, marks or designs of PwC or its related entities.
            </li>
            <li>
              Rights of third parties - Except as set out in clause 8.5, a person or entity that is not a party to the Contract has no rights and may not enforce any term of the Contract.
            </li>
            <li>
              Survival. Any clause that is intended to survive termination of the SaaS Terms will do so including, but not limited to, clauses 3-9 and
              11-13.
            </li>
            <li>
              Relationship - PwC is an independent contractor to Client and is not in any partnership, joint venture, fiduciary, employment, agency or other relationship with Client. Neither party to the Contract has the power to bind the other.
            </li>
            <li>
              Independence - The parties will cooperate regarding the Client and PwC's audit independence requirements. The Client will notify PwC as soon as it is permitted by law to do so of any proposed change that the Client believes is likely to occur (for instance, in the Client's group structure, or in relation to individuals that have significant influence over the Client) that could result in the relationship between the parties not being permitted under any audit independence rule or regulation. Information about when, in PwC's opinion, may not be permitted is available from PwC on request. This information is solely to help Client comply with this notification requirement and is not independent advice.
            </li>
            <li>
              Changes - Any amendment to the Contract is effective only when agreed in writing.
            </li>
            <li>
              Governing Law - The Contract and any dispute arising from the Contract, whether contractual or non-contractual, will be exclusively governed by the laws of Finland, excluding its principles and rules on conflict of laws.
            </li>
          </ol>
          <li>
            <h3>Definitions</h3>
            The words and expressions defined in the Cover Sheet or within the SaaS Terms will have those meanings throughout the Contract. The following words and expressions have the meanings given to them below:
          </li>
          <ul>
            <li>
              “Affiliate” means an entity that directly or indirectly controls, is controlled by, or is under common control with Client.
            </li>
            <li>
              “Client Materials” means any information or materials that: (a) Client provides to PwC in connection with the Contract (including for purposes of making them available to End Users via the Software); or (b) Client or any End User inputs, transmits or uploads to, or otherwise stores or processes on or through, the Software.
            </li>
            <li>
              “Confidential Information” with respect to a disclosing party, means all information, data and material disclosed by such disclosing party to the receiving party (in whatever form) that is marked or described as, or provided under circumstances reasonably indicating it is, confidential or proprietary. PwC's Confidential Information includes the Software, the Documentation and all non-public information and materials (technical, business and otherwise) related to the Software (and the use of it) or provided by PwC to Client in relation to the Contract.
            </li>
            <li>
              “Documentation” means the standard technical documentation and user guides for the Software, as generally provided by PwC.
            </li>
            <li>
              “End User” means, subject to clause 3.1, any Client or Client Affiliate personnel permitted by Client to access and use the Software.
            </li>
            <li>
              “Intellectual Property Rights” means all copyrights, patents, rights with respect to trademarks, service marks, and trade dress, trade secret rights, rights in domain names, rights with respect to databases and other compilations and collections of data or information, publicity rights, and other intellectual and industrial property rights anywhere in the world, whether statutory, common law or otherwise.
            </li>
            <li>
              “PwC Firm” means any entity or partnership within the PricewaterhouseCoopers network of firms, each of which is a separate legal entity.
            </li>
            <li>
              “PwC Subcontractors” means other PwC Firms and other subcontractors, contractors and IT and other services providers used by PwC or other PwC Firms.
            </li>
            <li>
              “Software Account” means an End User account associated with a unique user name and password, through which the End User may access and use the Software as permitted under the Contract.
            </li>
          </ul>
        </ol>
      </div>
      <div className="login-footer">
        <PageFooter />
      </div>
    </div>
    <div className="">
      <div className="background-right" />
    </div>
  </div>
)
export default TermsAndConditions;
