import { Breadcrumb, BreadcrumbItem, Button, Column, Panel, Search, Tab, Table, Tabs } from "@appkit4/react-components";
import { AnalysisNavigator, LoaderWrapper, toastMessage } from "components/common/helpers";
import Loader from "components/common/loader";
import Paginate from "components/common/paginate";
import AnalysisTemplate from "components/layout/analysisTemplate";
import { FetchAnalysisData, FetchDisabledAuthorisations } from "queries/hooks/analysis/analysis";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { getDate, searchFilter } from "services/common";
import { exportClipboard, exportExcel } from "services/export";
import { DisabledItem, DisabledItemType } from "types/analysis";
import { disabledAuthorisationsHeaders } from "types/exportHeaders";

const DisabledAuthorisations: FC = () => {
  const { analysisId } = useParams();
  const [search, setSearch] = useState("");
  const [type, setType] = useState<DisabledItemType>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(25);

  const { data: analysisData, isPending: isPendingAnalysis, error: errorAnalysis } = FetchAnalysisData(analysisId);
  const { data, isPending: isPendingAuthorisations, error: errorAuthorisations } = FetchDisabledAuthorisations(analysisId);


  const getData = () => data?.filter(f => searchFilter(f, ["businessProcess", "test", "function", "transaction"], search))
    .filter(f => f.type === type).map((m: DisabledItem) => ({
      businessProcess: m.businessProcess,
      test: m.test,
      function: m.function?.split(";")[0],
      transaction: m.transaction?.split(";")[0],
      functionDescription: m.function?.split(";")[1],
      transactionDescription: m.transaction?.split(";")[1]
    })) || []

  const getType = (type: DisabledItemType) => {
    switch (type) {
      case DisabledItemType.Missing:
        return <><p>Following table lists the Transactions that do not exist in the system. The table includes only the Transactions that are part of the selected Ruleset.</p></>
      case DisabledItemType.Transaction:
        return <><p>Following table lists the locked Transactions. The table includes only the Transactions that are part of the selected Ruleset.</p></>
      default:
        return "Unknown";
    }
  }
  const getFileTypeName = (type: DisabledItemType) => {
    switch (type) {
      case DisabledItemType.Missing:
        return "Transactions that do not exist in the system";
      case DisabledItemType.Transaction:
        return "Locked Transactions";
      default:
        return "Unknown";
    }
  }
  const fileExport = () => {
    const fileType = getFileTypeName(type);
    let pageHeaders = [
      ["Insights Access Risks"],
      ["Client", `${analysisData?.client?.name}`],
      ["SAP System", `${analysisData?.sapSystem?.sapSystemName} - ${analysisData?.sapSystem?.sapClient} - ${analysisData?.sapSystem?.sapNickname}`],
      ["Extraction date", getDate(analysisData?.extractionDate || "")],
      ["List type", fileType]
    ];
    let filename = `${analysisData?.client?.name}-${analysisData?.sapSystem?.sapNickname}-${fileType}`;

    toastMessage("Exporting data");
    exportExcel(getData(), disabledAuthorisationsHeaders, filename, pageHeaders);
  }
  const copyToClipboard = async () => {
    await toastMessage("Processing data");
    exportClipboard(getData(), disabledAuthorisationsHeaders);
    await toastMessage("Data copied to the clipboard");
  }

  return (
    <AnalysisTemplate>
      <Breadcrumb>
        <BreadcrumbItem>Analysis</BreadcrumbItem>
        <BreadcrumbItem>Disabled Authorisations</BreadcrumbItem>
      </Breadcrumb>
      <AnalysisNavigator />
      <LoaderWrapper loading={[isPendingAnalysis, isPendingAuthorisations]} errors={[errorAnalysis, errorAuthorisations]}>
        <h1>Disabled Authorisations</h1>
        <Tabs activeIndex={type} onTabChange={(index: number) => setType(index)}>
          <Tab label="Locked transactions"></Tab>
          <Tab label="Transactions that do not exist in the system"></Tab>
        </Tabs>
        <Panel>
          <div style={{ padding: "16px 0" }}>
            {
              getType(type)
            }
          </div>
          <div className="flex items-center gap-4 mt-4 pt-4 pb-4">
            <div className="grow">
              <Search
                searchType={"secondary"}
                value={search}
                onChange={(value: string) => {
                  setSearch(value);
                }}
                className="list-filter"
              />
            </div>
            <div>
              <Button icon="icon-copy-outline" onClick={() => copyToClipboard()}>Copy</Button>
            </div>
            <div>
              <Button icon="icon-table-data-outline" onClick={() => fileExport()}>Export</Button>
            </div>
          </div>
          <Table
            originalData={getData()}
            striped
            hasTitle
            pageSize={offset}
            currentPage={currentPage}
          >
            <Column field="businessProcess" sortKey="businessProcess">Business Process</Column>
            <Column field="test" sortKey="test">Test</Column>
            <Column field="function" sortKey="function" renderCell={(row: DisabledItem) => {
              return (
                <>
                  <b>{row.function}</b>
                  <p>{row.functionDescription}</p>
                </>
              )
            }}>Function</Column>
            <Column field="transaction" sortKey="transaction" renderCell={(row: DisabledItem) => {
              return (
                <>
                  <b>{row.transaction}</b>
                  <p>{row.transactionDescription}</p>
                </>
              )
            }}>Transaction</Column>
          </Table>
          <Paginate
            getTotalPages={Math.ceil(getData().length / offset) || 0}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setPageOffset={setOffset}
          />
        </Panel>
      </LoaderWrapper>
    </AnalysisTemplate>
  );
}

export default DisabledAuthorisations;