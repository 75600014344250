import { jwtDecode, JwtPayload } from "jwt-decode";
import { FC, useCallback, useEffect, useState } from "react"
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom"
import { postData } from "./api-actions";
import { Button, Modal } from "@appkit4/react-components";

const PwCID_URI = process.env.REACT_APP_PWCID_URI || "";
const EXPIRATION = 120;

const Authentication: FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const [ticker, setTicker] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [checkInterval] = useState(60000);
  const [cookies, setCookie] = useCookies();
  const location = useLocation();

  const refreshToken = useCallback(async () => {
    let refresh_token = cookies['refresh_token'];
    if (!refresh_token) navigate("/login?action=session-expired");
    let response = await postData<{ refreshToken: string }, { id_token: string, access_token: string, refresh_token: string }>(`authentication/refresh`, { refreshToken: refresh_token });
    if (response.isError || !response.result)
      setShowModal(true);
    else {
      localStorage.setItem("access_token", response.result.access_token);
      setCookie("id_token", response.result.id_token, {
        maxAge: 1800,
        secure: true,
        sameSite: "lax"
      });
      setCookie("refresh_token", response.result.refresh_token, {
        maxAge: 1800,
        secure: true,
        sameSite: "lax"
      });
    }
  }, [cookies, navigate, setCookie]);

  const redirect = useCallback((logout: boolean = false) => {
    sessionStorage.setItem("redirect", `${location.pathname}${location.search}`);
    if (logout) {
      localStorage.removeItem("access_token");
      navigate("/login?action=session-expired");
    }
    else
      window.location.assign(encodeURI(PwCID_URI));
  }, [location, navigate]);

  const checkValidity = useCallback((token: string) => {
    const payload = jwtDecode<JwtPayload>(token);
    const currentTimestamp = Date.now() / 1000;
    if (payload.exp) {
      let expiration = (payload.exp - currentTimestamp);
      if (expiration < 10)
        redirect(true);
      if (expiration < EXPIRATION)
        refreshToken();      
    }
  }, [redirect, refreshToken]);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const interval = setInterval(() => setTicker(ticker + 1), checkInterval);
    if (token) {
      checkValidity(token);
    } else {
      localStorage.removeItem("access_token");
      redirect(true);
    }
    return () => clearInterval(interval);
  }, [checkValidity, ticker, navigate, redirect, checkInterval]);
  return (<>
    {children}
    <ExpirationModal visible={showModal} logout={() => redirect(true)} cancel={() => {
      setShowModal(false);
    }} confirm={() => redirect()}/>
  </>);
}

const ExpirationModal: FC<{ visible: boolean, logout: () => void, cancel: () => void, confirm: () => void, }> = ({ visible, cancel, confirm, logout }) => {
  const [ticker, setTicker] = useState(0);
  const navigate = useNavigate();
  const [expiration, setExpiration] = useState(180);
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if(token) {
      const payload = jwtDecode<JwtPayload>(token);
      if(payload.exp) {
        if(expiration <= 1) {
          localStorage.removeItem("access_token");
          navigate("/login?action=session-expired");
        }
        const interval = setInterval(() => setTicker(ticker + 1), 1000);
        const currentTimestamp = Date.now() / 1000;
        setExpiration((payload.exp - currentTimestamp))
        return () => clearInterval(interval);
      }
    }
  }, [ticker, expiration, navigate]);
  return (
    <Modal
    visible={visible}
    title={"Your session is about to expire"}
    ariaLabel={"Session expiration warning"}
    onCancel={cancel}
    modalStyle={{ width: '33.75rem' }}
    footerStyle={{ 'paddingTop': '8px', 'marginTop': '-8px', 'minHeight': '64px' }}
    header={""}
    icons={""}
    footer={
      <>
        <Button onClick={cancel} kind="secondary">Snooze (60 seconds)</Button>
        <Button onClick={confirm} kind="negative">Refresh session</Button>
      </>
    }
  >
    <p className="mb-8">Automatic session refresh failed.</p>
    <p className="mb-8">Your session will expire in <b>{Math.floor(expiration)}</b> seconds.</p>
    <p className="mb-8">Would you like to refresh your session?</p>
    <p className="mb-8">If you do not refresh your session, you will be logged out.</p>
    <p className="mb-8">You can also snooze this message for 60 seconds.</p>
  </Modal>
  )
}
export default Authentication;