import { Panel } from "@appkit4/react-components";
import { customTooltip, LoaderWrapper } from "components/common/helpers";
import ToolTip from "components/common/tooltip";
import { useAtomValue } from "jotai";
import { filterAtom } from "jotai/store";
import { FetchUserGraph } from "queries/hooks/analysis/graphs";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { themeColours } from "style/theme";

const UserGraph: FC = () => {
  const { analysisId } = useParams();

  const userFilter = useAtomValue(filterAtom);

  const { data, isPending, error } = FetchUserGraph(analysisId, userFilter);

  const renderCustomizedLabel = (data: any) => {
    const { index, percent } = data;
    return (
      <text x="52%" y="33%" textAnchor="middle" dominantBaseline="middle" style={{ fontSize: 24, fontWeight: 500, fontFamily: '"PwC Helvetica Neue", sans-serif' }}>
        {index === 0 && `${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }
  return (
    <Panel
      title="User statistics"
      className="ap-panel-statistics"
      extra={(
        <ToolTip content={() => (
          <>
            <h3>How to view</h3>
            <p style={{ margin: "8px 0" }}>This graph shows the total number of users in the analysis and how many of those users have one or more matches.</p>
            <p style={{ margin: "8px 0" }}>The pie chart shows the proportion of users with one or more matches compared to users with no matches.</p>
            <p style={{ margin: "8px 0" }}>The table shows the number of users in each user type.</p>
          </>
        )} position="left">
          <span className="Appkit4-icon icon-information-fill ap-font-16 ap-panel-information"></span>
        </ToolTip>
      )}
    >
      <LoaderWrapper loading={[isPending]} errors={[error]} inline>
        <div className="flex flex-row">
          <div className="basis-2/5">
            <p className="ap-font-medium">Total no. of users</p>
            <p className="ap-font-xlarge">{data?.userTotal.toLocaleString()}</p>
            <ResponsiveContainer width={230} height={260} style={{ marginTop: 32 }}>
              <PieChart>
                <Pie
                  data={[
                    { name: "One or more test matches", value: data?.userCount },
                    { name: "No matches", value: (data?.userTotal || 0) - (data?.userCount || 0) }
                  ]}
                  dataKey="value"
                  nameKey="name"
                  cx={"50%"}
                  cy={"50%"}
                  innerRadius={40}
                  outerRadius={70}
                  accentHeight={10}
                  paddingAngle={5}
                  labelLine={false}
                  label={renderCustomizedLabel}
                >
                  <Cell fill={themeColours.ORANGE} />
                  <Cell fill={"#D1D1D1"} />
                </Pie>
                <Tooltip
                  wrapperStyle={{ zIndex: 2999 }}
                  content={customTooltip}
                />
                <Legend
                  content={(props) => {
                    const { payload } = props;
                    if (!payload) return null;
                    return (
                      <ul className="ap-legend" style={{ flexDirection: "column" }}>
                        {payload.map((entry, index) => (
                          <li key={`item-${index}`} className="ap-legend-item">
                            <span className="ap-legend-icon" style={{ backgroundColor: entry.color }} />
                            <span className="ap-legend-label">{entry.value}</span>
                          </li>
                        ))}
                      </ul>
                    );
                  }}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="basis-3/5">
            <div className="flex flex-wrap gap-4"
            >
              <div className="basis-1/2">
                <p>Dialog users</p>
                <p className="ap-font-large">{data?.userDialog?.toLocaleString()}</p>
              </div>
              <div className="grow break-after-column">
                <p>System users</p>
                <p className="ap-font-large">{data?.userSystem?.toLocaleString()}</p>
              </div>
              <div className="basis-1/2">
                <p>Service users</p>
                <p className="ap-font-large">{data?.userService?.toLocaleString()}</p>
              </div>
              <div className="grow">
                <p>Communication users</p>
                <p className="ap-font-large">{data?.userCommunication?.toLocaleString()}</p>
              </div>
            </div>
          </div>
        </div>
      </LoaderWrapper>
    </Panel>
  )
}

export default UserGraph;