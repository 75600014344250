import { Button, Table, Column, Search, Switch, Panel } from "@appkit4/react-components";
import Paginate from "components/common/paginate";
import { AnchorCatcher, CompositeIcon, LoaderWrapper, toastMessage } from "components/common/helpers";
import { FC, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getDate, isAutoScroll, parseMatches, searchFilter } from "services/common";
import { UserData, MatchTable, MatchingData } from "types/user";
import { exportClipboard, exportExcel } from "services/export";
import { matchHeaders } from "types/exportHeaders";
import { ListType, UserType } from "types/analysis";
import { useAtomValue } from "jotai";
import { filterAtom } from "jotai/store";
import { FetchAnalysisData } from "queries/hooks/analysis/analysis";
import { FetchUserMatches } from "queries/hooks/analysis/user";
import { ActiveFilters } from "components/layout/filters";

interface UserMatchesProps {
  type: ListType;
  typeId: string;
  userData?: UserData;
  matchingData?: MatchingData;
};

const UserMatches: FC<UserMatchesProps> = ({ type, typeId, userData, matchingData }) => {
  const location = useLocation();
  const [flatData, setFlatData] = useState<MatchTable[]>();
  const [filteredData, setFilteredData] = useState<MatchTable[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(50);
  const [search, setSearch] = useState("");
  const [match, setMatch] = useState(false);
  const { analysisId, userId } = useParams();

  const userFilter = useAtomValue(filterAtom);

  const { data: matchData, isPending: isMatchesPending, error: errorMatches } = FetchUserMatches(analysisId, userId, userFilter, type, typeId, userData, matchingData, match);

  const { data: analysisData, isPending: isAnalysisPending, error: errorAnalysis } = FetchAnalysisData(analysisId);

  // const getData = useCallback(() => , [search, flatData]);
  const getTotalPages = () => flatData ? Math.ceil(filteredData.length / offset) : 1;

  const scrollHere = () => {
    const element = document.getElementById("matches-top");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }

  useEffect(() => {
    setFilteredData(
      flatData?.filter(f => searchFilter(f, ["transaction", "function", "compositeRole", "compositeProfile", "profile", "role", "object", "field", "authorisation"], search)) || []
    )
  }, [search, flatData]);

  useEffect(() => {
    if (matchData)
      if ((type === ListType.Role || type === ListType.CompositeRole) && matchingData) {
        setFlatData(parseMatches(matchData, matchingData, type, typeId, match));
      } else if (userData) {
        setFlatData(parseMatches(matchData, userData, type, typeId, match));
      }
  }, [matchData, userData, matchingData, type, typeId, match]);

  useEffect(() => {
    let hash = location.hash.slice(1);
    if (isMatchesPending) {
      toastMessage(`Loading ${hash}`);
    } else {
      if (hash && !isAutoScroll()) {
        toastMessage(`${hash} loaded`,
          <a tabIndex={0} role="link" className="ap-notification-hyperlink ap-link height" onClick={() => {
            scrollHere();
          }}>View results</a>,
          "icon-success-fill",
          6000
        );
      } else {
        toastMessage("Matches loaded");
        scrollHere();
      }
    }
  }, [isMatchesPending, location.hash]);

  const customRender = (row: MatchTable, field: string) => {
    switch (field) {
      case "role":
        return (
          <>
            {row.compositeRole && <div className="flex gap-4 text-nowrap"><div><CompositeIcon isComposite={true} type="role" /></div><div><b>{row.compositeRole}</b></div></div>}
            {<div className="flex gap-4 text-nowrap"><div><CompositeIcon isComposite={false} type="role" /></div><div>{row.role}</div></div>}
          </>
        );
      case "profile":
        return (
          <>
            {row.compositeProfile && <div className="flex gap-4 text-nowrap"><div><CompositeIcon isComposite={true} type="profile" /></div><div><b>{row.compositeProfile}</b></div></div>}
            {<div className="flex gap-4 text-nowrap"><div><CompositeIcon isComposite={false} type="profile" /></div><div>{row.profile}</div></div>}
          </>
        );
      case "testedValues":
        return row[field].includes(",")
          ? (<>{row[field].split(",").map((m, index, array) => (<span key={`${m}-${index}`}>{checkValue(m)}{index < array.length - 1 && ", "}</span>))}</>)
          : checkValue(row[field]);
      case "authFrom":
        return row[field] === "*"
          ? <span className="Appkit4-icon icon-circle-star-fill"></span>
          : row[field]
    }
  }
  const checkValue = (value: string) => {
    switch (value.trim()) {
      case "*":
        return <span className="Appkit4-icon icon-circle-star-fill"></span>
      case "**":
        return <span className="Appkit4-icon icon-circle-star-fill ap-mandatory-asterisk"></span>
      default:
        return <span>{value.trim()}</span>;
    }
  }
  const fileExport = () => {
    if (!userData) return;
    let data = analysisData;
    if (!data) {
      toastMessage("No data to export");
      return;
    }
    let pageHeaders = [
      ["Insights - Access Risks"],
      ["Client:", `${data.client?.name}`],
      ["SAP System:", `${data.sapSystem?.sapSystemName} - ${data.sapSystem?.sapClient} - ${data.sapSystem?.sapNickname}`],
      ["Extraction date:", getDate(data.extractionDate || "")],
      ["Username:", userData.userName],
      ["Type", (userData.type && UserType[userData.type].name) || ""],
      ["Name:", matchData?.name?.toString() || ""],
    ]
    toastMessage("Exporting data");
    exportExcel(filteredData, matchHeaders, `${data.client?.name}-${data.sapSystem?.sapNickname}-${userData.userName}-${matchData?.name}-matches`, pageHeaders);
  }
  const copyToClipboard = async () => {
    await toastMessage("Processing data");
    exportClipboard(filteredData, matchHeaders);
    await toastMessage("Data copied to the clipboard");
  }
  return (
    <LoaderWrapper loading={[isAnalysisPending, isMatchesPending]} errors={[errorAnalysis, errorMatches]} inline>
      <Panel id="matches-top">
        {isAutoScroll() && <AnchorCatcher />}
        <div className="flex items-center gap-4 pt-4 pb-4"
        >
          <div className="grow">
            <Search
              searchType={"secondary"}
              onChange={(value: string) => {
                setSearch(value);
                if (currentPage !== 1)
                  setCurrentPage(1)
              }}
              className="list-filter"
            />
          </div>
          <div className="grow">
            <div>
              <Switch
                onChange={(value: boolean) => {
                  setMatch(value);
                }}
                showIndicator>Show duplicate matches</Switch>
            </div>
          </div>
          <div>
            <Button icon="icon-copy-outline" onClick={() => copyToClipboard()}>Copy</Button>
          </div>
          <div>
            <Button icon="icon-table-data-outline" onClick={() => fileExport()}>Export</Button>
          </div>
        </div>
        <ActiveFilters rows={filteredData.length || 0} />
        <Table
          originalData={filteredData}
          currentPage={currentPage}
          pageSize={offset}
          hasTitle
          condensed
          striped>
          <Column field="function" sortKey="function">Function</Column>
          <Column field="transaction" sortKey="transaction">Transaction</Column>
          <Column field="role" sortKey="role" renderCell={customRender}>Role</Column>
          <Column field="profile" sortKey="profile" renderCell={customRender} style={{ minWidth: 136 }}>Profile</Column>
          <Column field="authorisation" sortKey="authorisation" style={{ minWidth: 80 }}>Authorization</Column>
          <Column field="object" sortKey="object">Object</Column>
          <Column field="field" sortKey="field">Field</Column>
          <Column field="authFrom" renderCell={customRender}>Value from</Column>
          <Column field="authTo">Value to</Column>
          <Column field="testedValues" renderCell={customRender}>Tested values</Column>
        </Table>
        {
          getTotalPages() > 0 
            ? <Paginate
              getTotalPages={getTotalPages()}
              currentPage={currentPage}
              pageOffset={offset}
              setCurrentPage={setCurrentPage}
              setPageOffset={setOffset}
              scrollTo="matches-top"
            />
            : null
        }
      </Panel>
    </LoaderWrapper>
  )
}
export default UserMatches;