import { Button, Column, Drawer, DropdownButton, Input, Search, Table } from "@appkit4/react-components";
import { ConfirmationModal, FormError, LoaderWrapper, ValidationError, toastMessage } from "components/common/helpers";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { checkFormValues } from "services/common";
import { SapSystem, UserLevel } from "types/analysis";
import { useQueryClient } from "@tanstack/react-query";
import { FetchAnalyses } from "queries/hooks/administration/analysis";
import { FetchSystems, DeleteSystem, PostSystem, PutSystem } from "queries/hooks/administration/system";
import { jwtDecode } from "jwt-decode";
import { PwCJwt } from "types/common";
import { useCookies } from "react-cookie";

const initSystem: SapSystem = {
  systemId: "new",
  sapNickname: "",
  sapSystemName: "",
  sapClient: ""
}

const SystemView: FC = () => {
  const { clientId } = useParams();
  const [editItem, setEditItem] = useState<SapSystem>();
  const [deleteItem, setDeleteItem] = useState<SapSystem>();
  const [search, setSearch] = useState("");
  const [cookie] = useCookies();

  const queryClient = useQueryClient();

  const { data: systems, isPending: isSystemPending, error: errorSystem } = FetchSystems(clientId);

  const { data: analyses, isPending: isAnalysesPending, error: errorAnalyses } = FetchAnalyses(clientId);

  const { mutate: deleteSystem } = DeleteSystem(clientId);

  const handleDelete = async (system?: SapSystem) => {
    if (!system) return;
    deleteSystem(system, {
      onSuccess: () => {
        setDeleteItem(undefined);
        toastMessage("System deleted successfully");
        queryClient.invalidateQueries({ queryKey: ["systems", clientId] });
        return true;
      },
      onError: () => {
        toastMessage("Unable to delete system", "error");
      }
    });
  }

  const checkSystemUse = (systemId?: string) => {
    return !analyses?.some(f => f.systemId === systemId);
  }
  const checkUserLevel = (levels: UserLevel[]) => {
    const jwtData = jwtDecode<PwCJwt>(cookie["id_token"]);
    return jwtData?.userLevel && levels.includes(UserLevel[jwtData.userLevel as keyof typeof UserLevel]);
  }
  const customizeColumn = (row: SapSystem, field: string) => {
    switch (field) {
      case "actions":
        return checkUserLevel([UserLevel.PwCUser, UserLevel.ClientAdmin, UserLevel.PwCAdmin]) && (
          <DropdownButton
            compact
            splitButton
            kind="tertiary"
            data={[
              { label: "Edit", value: 1 },
              { label: "Delete system", value: 2 },
            ]}
            onSelect={async (value) => {
              switch (value) {
                case 1:
                  setEditItem(systems?.find(f => f.systemId === row.systemId));
                  break;
                case 2:
                  setDeleteItem(row);
                  break;
                default:
                  break;
              }
            }}
            onClick={async () => setEditItem(systems?.find(f => f.systemId === row.systemId))}
          >
            Edit
          </DropdownButton>
        );
      default:
        return row[field as keyof SapSystem];
    }
  }
  return (
    <LoaderWrapper loading={[isAnalysesPending, isSystemPending]} errors={[errorAnalyses, errorSystem]}>
      <h3>Systems</h3>
      {
        checkUserLevel([UserLevel.PwCAdmin]) && <p>A view for creating new systems and reviewing existing systems.</p>
      }
      <div className="flex items-center gap-4 mt-4 mb-4">
        <div className="shrink">
          <Button kind='primary' icon="icon-plus-outline" onClick={() => setEditItem(initSystem)} disabled={!checkUserLevel([UserLevel.ClientAdmin, UserLevel.PwCAdmin])}>New system</Button>
        </div>
        <div>
          <Search
            searchType={"secondary"}
            onChange={(value: string) => {
              setSearch(value);
            }}
            searchValue={search}
            className="list-filter"
          />
        </div>
      </div>
      <Table
        originalData={systems?.map(m => ({ ...m, inUse: checkSystemUse(m.systemId), actions: "" })) || []}
        hasTitle
        striped
      >
        <Column field="inUse" sortKey="inUse" renderCell={(row: SapSystem) => checkSystemUse(row.systemId) ? "No" : "Yes"}>In use</Column>
        <Column field="sapNickname" sortKey="sapNickname" renderCell={customizeColumn}>Nickname</Column>
        <Column field="sapSystemName" sortKey="sapSystemName" renderCell={customizeColumn}>System name</Column>
        <Column field="sapClient" sortKey="sapClient" renderCell={customizeColumn}>Client</Column>
        <Column field="actions" sortKey="actions" renderCell={customizeColumn}>Actions</Column>
      </Table>
      <ConfirmationModal
        visible={deleteItem !== undefined}
        title="Delete system"
        cancel={() => setDeleteItem(undefined)}
        confirm={async () => deleteItem && await handleDelete(deleteItem)}>
        {checkSystemUse(deleteItem?.systemId) ? <p>Are you sure you want to delete {deleteItem?.sapNickname}?</p> : <p>System is in use and cannot be deleted</p>}
      </ConfirmationModal>
      <Drawer
        initialFocus={false}
        mask={true}
        resizable={true}
        visible={editItem !== undefined}
        placement="right"
        title={editItem?.sapSystemName || "Add new system"}
        onClose={() => {
          setEditItem(undefined);
        }}
        closable={true}
      >
        {
          editItem !== undefined && <SystemForm
            systemData={editItem || {}}
            cancel={() => {
              setEditItem(undefined);
            }}
          />
        }
      </Drawer>
    </LoaderWrapper>
  )
}

const SystemForm: FC<{ systemData?: SapSystem, cancel: () => void }> = ({ systemData, cancel }) => {
  const { clientId } = useParams();

  const [system, setSystem] = useState<SapSystem>(systemData || initSystem);
  const [errors, setErrors] = useState<(keyof SapSystem)[] | undefined>();
  const [error, setError] = useState<string | null>(null);

  const queryClient = useQueryClient();

  const { mutate: addSystem, isPending: addingSystem } = PostSystem(clientId);

  const { mutate: editSystem, isPending: editingSystem } = PutSystem(clientId);

  const handleSubmit = async (system?: SapSystem) => {
    if (!system) return;
    if (!validateForm(system)) return;
    if (system.systemId === "new") {
      system.clientId = clientId || "";
      system.systemId = crypto.randomUUID();
      toastMessage("Adding system");
      addSystem(system, {
        onSuccess: () => {
          toastMessage("System added successfully");
          queryClient.invalidateQueries({ queryKey: ["systems", clientId] });
          cancel();
          return true;
        },
        onError: () => {
          setError("Unable to add a system");
        }
      });
    } else {
      toastMessage("Updating system");
      editSystem(system, {
        onSuccess: () => {
          toastMessage("System updated successfully");
          queryClient.invalidateQueries({ queryKey: ["systems", clientId] });
          cancel();
          return true;
        },
        onError: () => {
          setError("Unable to update system");
        }
      });
    }
  }

  const validateForm = (system: SapSystem) => {
    setErrors(undefined);
    setError(null);
    let errorList: (keyof SapSystem)[] = [];
    let check = checkFormValues(system, ["sapClient", "sapNickname", "sapSystemName"]);
    if (Array.isArray(check))
      errorList = check;
    if (errorList.length > 0) {
      setErrors(errorList);
      return false;
    }
    return true;
  }

  return (
    <div className="ap-input-form">
      <div className="flex flex-col gap-4">
        <div>
          <Input
            type="text"
            value={system?.sapNickname || ""}
            title="Nickname"
            onChange={(value: string) => setSystem({ ...system, sapNickname: value })}
            required
            error={errors?.includes("sapNickname")}
            errorNode={<ValidationError error="Nickname is required" />}
          />
        </div>
        <div>
          <Input
            type="text"
            value={system?.sapSystemName || ""}
            title="System name"
            onChange={(value: string) => setSystem({ ...system, sapSystemName: value })}
            required
            error={errors?.includes("sapSystemName")}
            errorNode={<ValidationError error="System name is required" />}
          />
        </div>
        <div>
          <Input
            type="text"
            value={system?.sapClient || ""}
            title="Client"
            onChange={(value: string) => setSystem({ ...system, sapClient: value })}
            required
            error={errors?.includes("sapClient")}
            errorNode={<ValidationError error="Client is required" />}
          />
        </div>
      </div>
      <FormError error={error} />
      <div className="ap-footer flex gap-4">
        <div className="grow">
          <Button onClick={cancel} kind="secondary">Cancel</Button>
        </div>
        <div>
          <Button
            onClick={() => handleSubmit(system)}
            kind="primary"
            loading={editingSystem || addingSystem}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SystemView;