import { SelectValue } from "@appkit4/react-components/esm/combobox/Combobox";
import { ListType } from "types/analysis";
import { MatchData, MatchingData, MatchTable, ProfileData, TestData, UserMatchData } from "types/user";

const getApi = () => process.env.REACT_APP_API_URI || ""; 
const lockStatuses = [32, 64, 96, 128, 160, 192, 224];

/**
 * Parses a date string into a locale date string.
 * 
 * @param {string} date - The date string to be parsed.
 * @param {boolean} [outside] - Whether the date is in a different format.
 * @returns {string} - The parsed date string.
 */
const getDate = (date: string, outside: boolean = false) => {
  let parsedDate = new Date(Date.parse(date)).toLocaleDateString(window.navigator.language);
  if(parsedDate !== "Invalid Date") {
    return parsedDate;
  }
  const separator = date.includes("/") ? "/" : ".";
  if(separator === "/"){
    const [month, day, year] = date.split("/");
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day)).toLocaleDateString(window.navigator.language);
  } else {
    const [day, month, year] = date.split(".");
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day)).toLocaleDateString(window.navigator.language);
  }
}

/**
 * Parses an object into a query string.
 * 
 * @param {object} values - The object to be parsed.
 * @returns {string} - The parsed query string.
 */
const parseParams = (values: object) => Object.entries(values)
  .map(([k, v]) => v !== undefined ? `${k}=${v}` : null)
  .filter(f => f !== null)
  .join("&");


/**
 * Parses the lock status code and returns a corresponding descriptive string.
 *
 * @param lockStatus - The numeric code representing the lock status.
 * @returns A string describing the lock status.
 *
 * Possible lock status codes and their descriptions:
 * - 32: "Locked by CUA central administrator"
 * - 64: "Locked by administrator"
 * - 96: "Locked by CUA central administrator + Locked after failed logon"
 * - 128: "Locked after failed logon"
 * - 160: "Locked by CUA central administrator + Locked after failed logon"
 * - 192: "Locked by administrator + Locked after failed logon"
 * - 224: "Locked by Administrator"
 * - Default: An empty string
 */
const parseLockStatus = (lockStatus: number) => {
  switch (lockStatus) {
    case 32:
      return "Locked by CUA central administrator";
    case 64:
      return "Locked by administrator";
    case 96:
      return "Locked by CUA central administrator + Locked after failed logon";
    case 128:
      return "Locked after failed logon";
    case 160:
      return "Locked by CUA central administrator + Locked after failed logon";
    case 192:
      return "Locked by administrator + Locked after failed logon";
    case 224:
      return "Locked by Administrator";
    default:
      return "";
  }
}


/**
 * Parses an array of numbers and returns the sum of its elements.
 *
 * @param testFilter - An array of numbers to be summed.
 * @returns The sum of the numbers in the provided array.
 */
const parseTestFilter = (testFilter: number[]) => testFilter.reduce((a, b) => a + b, 0);


/**
 * Retrieves a test object from an array of tests based on a specified function ID.
 *
 * @param tests - An array of test data objects.
 * @param functionId - The ID of the function to search for within the test data.
 * @returns The test object that contains the specified function ID, or null if not found.
 */
const getTest = (tests: TestData[], functionId: string) => {
  for (let test of tests) {
    for (let func of test.functions)
      if (func.functionId === functionId)
        return test;
  }
  return null;
}

/**
 * Parses match data and user data to generate a list of match table entries.
 *
 * @param matchData - The match data containing transactions and matches.
 * @param userData - The user data containing tests, roles, and profiles.
 * @param type - The type of list to filter by (e.g., Role, CompositeRole).
 * @param typeId - The identifier for the specific type.
 * @param filter - A boolean indicating whether to filter duplicates (default is true).
 * @returns An array of match table entries.
 */
const parseMatches = (matchData: UserMatchData, userData: MatchingData, type: ListType, typeId: string, filter: boolean = true) => {
  let tempData: MatchTable[] = [];
  for (let transaction of matchData.data) {
    let profileIds = transaction.matches.map(m => m.profileId);
    // let tempFunc = type === "test" || type === "all"
    //   ? test?.functions.find(f => f.functionId === transaction.functionId)?.identifier || null
    //   : type === "transaction"
    //     ? userData.tests.find(f => f.functions.map(m => m.functionId).includes(transaction.functionId))?.identifier || null
    //     : null;
    let test = getTest(userData.tests, transaction.functionId);

    let tempFunc = test?.functions.find(f => f.functionId === transaction.functionId)?.identifier;

    if(!tempFunc) continue;
    // if (test) //  || ["role", "compositeRole"].includes(type)
    // {
    if (type !== ListType.Role)
      for (let compositeRole of userData.roles.filter(f => type === ListType.CompositeRole ? (f.isComposite && f.roleId === typeId) : f.isComposite)) {
        if (compositeRole.roles)
          for (let role of compositeRole.roles)
            if (role.profiles)
              for (let profile of role.profiles.filter(m => profileIds.includes(m.profileId))) {
                let matches = transaction.matches.filter(f => f.profileId === profile.profileId);
                if (matches)
                  for (let match of matches) {
                    tempData.push({
                      test: test?.identifier,
                      function: tempFunc,
                      profile: profile.name,
                      compositeRole: compositeRole.name,
                      role: role.name,
                      transaction: transaction.identifier,
                      authorisation: match.name,
                      object: match.object,
                      authFrom: match.authFrom,
                      authTo: match.authTo,
                      field: match.field,
                      testedValues: match.testedValues,
                    });
                  }
              }
      }
      for (let compositeProfile of userData.profiles.filter(f => f.isComposite)) {
        if (compositeProfile.profiles)
          for (let profile of compositeProfile.profiles.filter(m => profileIds.includes(m.profileId))) {
            let matches = transaction.matches.filter(f => f.profileId === profile.profileId);
            if (matches)
              for (let match of matches) {
                tempData.push({
                  test: test?.identifier,
                  function: tempFunc,
                  profile: profile.name,
                  compositeProfile: compositeProfile.name,
                  transaction: transaction.identifier,
                  authorisation: match.name,
                  object: match.object,
                  authFrom: match.authFrom,
                  authTo: match.authTo,
                  field: match.field,
                  testedValues: match.testedValues,
                });
              }
          }
      }
    if (type !== ListType.CompositeRole)
      for (let role of userData.roles.filter(f => type === ListType.Role ? (!f.isComposite && f.roleId === typeId) : !f.isComposite)) {
        if (role.profiles)
          for (let profile of role.profiles.filter(m => profileIds.includes(m.profileId))) {
            let matches = transaction.matches.filter(f => f.profileId === profile.profileId);
            if (matches)
              for (let match of matches)
                if (filter || filterDuplicates(tempData, match, profile, transaction.identifier)) {
                  tempData.push({
                    test: test?.identifier,
                    function: tempFunc,
                    profile: profile.name,
                    role: role.name,
                    transaction: transaction.identifier,
                    authorisation: match.name,
                    object: match.object,
                    authFrom: match.authFrom,
                    authTo: match.authTo,
                    field: match.field,
                    testedValues: match.testedValues,
                  });
                }
          }
      }

    for (let profile of userData.profiles.filter(f => !f.isComposite)) {
      let matches = transaction.matches.filter(f => f.profileId === profile.profileId);
      if (matches)
        for (let match of matches)
          if (filter || filterDuplicates(tempData, match, profile, transaction.identifier)) {
            tempData.push({
              test: test?.identifier,
              function: tempFunc,
              profile: profile.name,
              transaction: transaction.identifier,
              authorisation: match.name,
              object: match.object,
              authFrom: match.authFrom,
              authTo: match.authTo,
              field: match.field,
              testedValues: match.testedValues,
            });
          }

    }
  }
  // }

  return tempData;
}


/**
 * Filters out duplicate entries from the provided temporary data array based on the specified match, profile, and transaction.
 *
 * @param tempData - An array of `MatchTable` objects to filter.
 * @param match - The `MatchData` object to compare against.
 * @param profile - The `ProfileData` object to compare against.
 * @param transaction - The transaction string to compare against.
 * @returns A boolean indicating whether the specified match, profile, and transaction combination is unique within the `tempData` array.
 */
const filterDuplicates = (tempData: MatchTable[], match: MatchData, profile: ProfileData, transaction: string) =>
  tempData.filter(f => f.transaction === transaction && f.object === match.object && f.profile === profile.name && f.authorisation === match.name && f.testedValues === match.testedValues).length === 0;

const isAutoScroll = () => {
  let autoScroll = localStorage.getItem("autoScroll") === "true";
  return autoScroll;
}

/**
 * Checks the values of an array against a list of fields.
 * @param array - The array to check.
 * @param fields - The list of fields to check against.
 * @returns An array of fields that have errors, or `true` if all fields are valid, or `false` if the array is empty.
 */
const checkFormValues = <T>(array: T, fields: Array<keyof T>) => {
  let errors: Array<keyof T> = [];
  if (array && Object.keys(array).length > 0)
    {
      for (let x of fields)
        switch (typeof array[x]) {
          case "number":
            if(array[x] === undefined && typeof array[x] === "number")
              errors.push(x);
            break;
          default:
            if(array[x] === undefined || (array[x] as string).trim().length === 0)
              errors.push(x);
            break;
        }
      if(errors.length > 0)
        return errors;
      else
        return [];
    }
  else {
    return fields;
  }
}

/**
 * Filters the data based on the search string and specified keys.
 * 
 * @template T - The type of the data being filtered.
 * @param {T} data - The data to be filtered.
 * @param {Array<keyof T>} keys - The keys to be searched in the data.
 * @param {string} [search] - The search string.
 * @returns {boolean} - Returns true if the data matches the search criteria, otherwise false.
 */
const searchFilter = <T>(data: T, keys: Array<keyof T>, search?: string) => {
  if (!search || search.length < 2) return true;
  if (typeof data === "object" && data)
    for (let key of keys)
      if ((data[key] as string) !== undefined && (data[key] as string !== null) && (data[key] as string).toLowerCase().includes(search.toLowerCase()))
        return true;

  return false;
}

/**
 * Filters data based on a specified key and an optional selection criteria.
 *
 * @template T - The type of the data object.
 * @param {T} data - The data object to be filtered.
 * @param {keyof T} key - The key in the data object to filter by.
 * @param {SelectValue} [select] - An optional selection criteria. Can be an array of strings.
 * @returns {boolean} - Returns true if the data matches the selection criteria or if no selection criteria is provided.
 */
const selectFilter = <T>(data: T, key: keyof T, select?: SelectValue) => {
  if (Array.isArray(select)) {
    if (select.length === 0) return true;
    if(Array.isArray(data[key])) 
      return (data[key] as string[]).some(s => select.includes(s)) 
    else 
      return select.includes(data[key] as string);
  }
  return true;
}

/**
 * Determines the appropriate text color (either dark or light) based on the given background color.
 *
 * @param fill - The background color in hexadecimal format (e.g., "#FFFFFF").
 * @returns A string representing the text color in hexadecimal format ("#333" for dark text, "#FFF" for light text).
 */
const getTextColour = (fill: string) => {
  const hex = fill.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const shade = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return shade < 0.5 ? "#333" : "#FFF";
}

/**
 * Compares two strings and sorts them in ascending order.
 * 
 * The comparison is based on the lexicographical order of the strings.
 * If the strings are equal in lexicographical order, their lengths are compared.
 * 
 * @param a - The first string to compare.
 * @param b - The second string to compare.
 * @returns A negative number if `a` should come before `b`, a positive number if `a` should come after `b`, or 0 if they are equal.
 */
const sortValues = (a: string, b: string) => {
  if (a > b || a.length > b.length) return 1;
  if (a < b || a.length < b.length) return -1;
  return 0;
}

/**
 * Paginates an array of data.
 *
 * @template T - The type of the data.
 * @param {T} data - The data to paginate. If the data is not an array, it will be returned as is.
 * @param {number} currentPage - The current page number (1-based index).
 * @param {number} pageOffset - The number of items per page.
 * @returns {T} - A subset of the data corresponding to the current page if the data is an array, otherwise the original data.
 */
const paginate = <T>(data: T, currentPage: number, pageOffset: number) => {
  if (Array.isArray(data))
    return data.slice((currentPage - 1) * pageOffset, currentPage * pageOffset)
  else
    return data;
}

/**
 * Converts an object to a URL query string.
 *
 * @template T - The type of the input object.
 * @param {T} data - The object to be converted to a query string.
 * @returns {string} - The resulting query string. If the input is not an object or is null/undefined, an empty string is returned.
 */
const objToParams = <T>(data: T) => {
  if (typeof data === "object" && data)
    return Object.keys(data).map(key => data[key as keyof T] ? key + '=' + data[key as keyof T] : "").filter(f => f.length !== 0).join('&');
  else
    return ""
}

/**
 * Updates the given URLSearchParams object with the specified key-value pair.
 * 
 * @param key - The key to update in the search parameters.
 * @param value - The value to set for the specified key. Can be a single value or an array of values.
 * @param searchParams - The URLSearchParams object to update.
 * 
 * @returns The updated URLSearchParams object.
 * 
 * @remarks
 * - If the value is falsy, the key is deleted from the search parameters.
 * - If the value is an array, it is joined with a colon (":") and set as the value for the key.
 * - If the value is an empty array, the key is deleted from the search parameters.
 * - The page parameter ("p") is always reset to "1" after updating the key-value pair.
 */
const updateParams = (key: string, value: SelectValue, searchParams: URLSearchParams) => {
  if (!value)
    searchParams.delete(key);
  else if (Array.isArray(value)) {
    if (value.length === 0)
      searchParams.delete(key);
    else
      searchParams.set(key, value.join(":"));
  }
  else {
    searchParams.set(key, value.toString());
  }
  searchParams.set("p", "1");
  return searchParams
}

export {
  getDate,
  getApi,
  parseParams,
  checkFormValues,
  objToParams,
  paginate,
  parseTestFilter,
  parseMatches,
  parseLockStatus,
  searchFilter,
  selectFilter,
  updateParams,
  isAutoScroll,
  getTextColour,
  lockStatuses,
  sortValues
}