import { FC, ReactNode } from "react";
import Authentication from "services/authentication";
import PageFooter from "./pageFooter";
import PageHeader from "./pageHeader";

const ClientTemplate: FC<{ children?: ReactNode }> = ({ children }) => (
  <Authentication>
    <div>
      <PageHeader />
      <div className="p-4 pt-7 w-full" id="dataview">
        {children}
        <PageFooter />
      </div>
    </div>
  </Authentication>
)

export default ClientTemplate;