import { FC, ReactNode } from "react";
import Authentication from "services/authentication";
import Sidebar from "./sidebar";
import PageFooter from "./pageFooter";
import PageHeader from "./pageHeader";
import { collapsedAtom } from "jotai/store";
import { useAtomValue } from "jotai";

const AnalysisTemplate: FC<{ children?: ReactNode }> = ({ children }) => {
  const collapsed = useAtomValue(collapsedAtom);
  return (
    <Authentication>
      <div>
        <PageHeader />
        <div className="flex flex-row">
          <div className={collapsed ? "shrink": "basis-1/6" }>
            <Sidebar />
          </div>
          <div className="grow p-4 pt-7" id="dataview">
            {children}
            <PageFooter />
          </div>
        </div>
      </div>
    </Authentication>
  );
}

export default AnalysisTemplate;