import { useCallback, useEffect, useState } from "react";
import { checkFormValues, searchFilter } from "services/common";
import {  Statuses, rulesetTableRender } from "./ruleEditor";
import { ClientData } from "types/analysis";
import { RuleFunction, RuleStatus, Test, TestRelation } from "types/ruleset";
import Loader from "components/common/loader";
import { Badge, Button, Checkbox, Column, Combobox, Drawer, DropdownButton, Input, ItemDataType, List, ListItem, Search, Select, Table, Tag, TextArea } from "@appkit4/react-components";
import ToolTip from "components/common/tooltip";
import Paginate from "components/common/paginate";
import { ConfirmationModal, FormError, FormWarning, TestIcon, toastMessage, ValidationError } from "components/common/helpers";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SelectValue } from "@appkit4/react-components/esm/combobox/Combobox";
import { useQueryClient } from "@tanstack/react-query";
import { DeleteTest, FetchTests, PostTest, PutTest } from "queries/hooks/ruleset/test";
import { FetchClients } from "queries/hooks/administration/client";
import { FetchFunctions } from "queries/hooks/ruleset/function";
import { FetchBusinessProcesses, FetchCategories } from "queries/hooks/ruleset/util";

const initTest: Test = {
  testId: "new",
  versionNumber: 1,
  versionStatus: RuleStatus.Draft,
  type: "SA",
  clientId: "00000000-0000-0000-0000-000000000000",
  identifier: "",
  description: "",
  versionComments: "Initial version",
  functionRelations: [],
  categoryId: "",
  businessProcessId: ""
}

const TestEditor: React.FC = () => {
  const [search, setSearch] = useState("");
  const [clientFilter, setClientFilter] = useState<string | undefined>(undefined);
  const [statusFilter, setStatusFilter] = useState<number | undefined>(undefined);
  const [editItem, setEditItem] = useState<Test>();
  const [deleteVisible, setDeleteVisible] = useState<Test>();
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(100);

  const { testId } = useParams();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { data: tests, isPending: testsPending} = FetchTests();
  const { data: clients, isPending: clientsPending } = FetchClients();
  const { data: functions, isPending: functionsPending } = FetchFunctions();
  const { mutate: deleteTest } = DeleteTest();

  const handleDelete = async () => {
    if (!deleteVisible) return;
    deleteTest(deleteVisible, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["tests"] });
        setDeleteVisible(undefined);
      },
      onError: () => {
        toastMessage("Error deleting test", null, "error");
      }
    });
  }
  const copyTest = (test: Test) => {
    let newTest = { ...test, testId: "new", versionNumber: test.versionNumber++, identifier: "Copy of " + test.identifier, versionComments: "Copy of " + test.versionComments, versionStatus: RuleStatus.Draft };
    setEditItem(newTest);
  }

  const getData = useCallback(() => {
    return tests?.filter(f => searchFilter(f, ["identifier"], search))
      .filter(f => searchFilter(f, ["clientId"], clientFilter))
      .filter(f => statusFilter === undefined || f.versionStatus === statusFilter)
      .map(m => ({ ...m, actions: "" })) || [];
  }, [search, tests, clientFilter, statusFilter]);

  const getTotalPages = useCallback(() => Math.ceil((tests?.length || offset) / offset), [tests, offset]);

  const close = () => {
    setEditItem(undefined);
    navigate("/ruleset/tests");
  }
  useEffect(() => {
    if (testId) {
      let item = tests?.find(f => f.testId === testId);
      if (item) setEditItem(item);
    }
  }, [testId, tests])
  return (testsPending || clientsPending || functionsPending
    ? <Loader loadingType="circular"></Loader>
    : (
      <>
        <h3 id="list-top">Tests</h3>
        <div className="flex items-center gap-4 mt-4 mb-4">
          <div className="shrink">
            <Button kind='primary' icon="icon-plus-outline" onClick={() => {
              setEditItem(initTest);
            }}>New Test</Button>
          </div>
          <div>
            <Search
              searchType={"secondary"}
              onChange={(value: string) => {
                setSearch(value);
              }}
              searchValue={search}
              className="list-filter"
            />
          </div>
          <div>
            <Combobox
              placeholder="Filter by client"
              data={clients?.map(client => ({ label: client.name, value: client.clientId })).concat({ label: "Default", value: "00000000-0000-0000-0000-000000000000" }) || []}
              value={clientFilter}
              onSelect={value => setClientFilter(value.toString())}
            />
          </div>
          <div>
            <Combobox
              placeholder="Status"
              data={Statuses}
              value={statusFilter}
              onSelect={value => setStatusFilter(value as number)}
              onClear={() => setStatusFilter(undefined)}
            />
          </div>
        </div>
        <Table
          originalData={getData()}
          hasTitle
          striped
          currentPage={currentPage}
          pageSize={offset}
        >
          <Column field="identifier" sortKey="identifier" renderCell={(row: Test, field: string) => (
            <Link to={`/ruleset/tests/${row.testId}`}>
              <b>{row.identifier}</b>
              <p>{row.description}</p>
            </Link>
          )}>Test</Column>
          <Column field="type" sortKey="type" renderCell={(row: Test, field: string) => <TestIcon type={row.type || "SA"} />}>Test type</Column>
          <Column field="clientId" sortKey="clientId" renderCell={(row: Test, field: string) => rulesetTableRender(row, field, clients || [])}>Client</Column>
          <Column field="versionNumber" sortKey="versionNumber" renderCell={(row: Test, field: string) => rulesetTableRender(row, field, clients || [])}>Version</Column>
          <Column field="versionStatus" sortKey="versionStatus" renderCell={(row: Test, field: string) => rulesetTableRender(row, field, clients || [])}>Status</Column>
          <Column field="functionRelations" sortKey="functionRelations" renderCell={(row: Test, field: string) => (
            <>
              {row.functionRelations?.map(m => m.function).map(f => f && (
                <div key={f.functionId}>
                  <b>{f.identifier}</b>
                  <p>{f.description}</p>
                </div>
              ))}
            </>
          )}
          >Functions</Column>
          <Column field="actions" sortKey="actions" renderCell={(row: Test, field: string) =>
            <DropdownButton
              compact
              kind="tertiary"
              splitButton
              data={[
                { label: "Edit", value: 1 },
                { label: "Make a copy", value: 2 },
                { label: "Delete", value: 3, disabled: row.versionStatus === RuleStatus.Current }
              ]}
              onSelect={(value) => {
                switch (value) {
                  case 1:
                    navigate(`/ruleset/tests/${row.testId}`);
                    break;
                  case 2:
                    copyTest(row)
                    break;
                  case 3:
                    row.testId && setDeleteVisible(row)
                    break;
                  default:
                    break;
                }
              }}
              onClick={() => { navigate(`/ruleset/tests/${row.testId}`) }}
            >
              Edit
            </DropdownButton>
          }>Actions</Column>
        </Table>
        <Paginate
          getTotalPages={getTotalPages()}
          currentPage={currentPage}
          pageOffset={offset}
          setCurrentPage={setCurrentPage}
          setPageOffset={setOffset}
        />
        <ConfirmationModal
          visible={deleteVisible !== undefined}
          title="Delete test"
          cancel={() => setDeleteVisible(undefined)}
          confirm={async () => await handleDelete()}>
          <p>Are you sure you want to delete { deleteVisible?.identifier || "this test" }?</p>
        </ConfirmationModal>
        <Drawer
          visible={editItem !== undefined}
          initialFocus={true}
          mask={true}
          resizable={true}
          placement="right"
          title={editItem?.testId === "new" ? "New test" : editItem?.identifier || "Edit test"}
          onClose={close}
          style={{ minWidth: 1166 }}
        >
          {editItem && <TestForm clients={clients || []} item={editItem} close={close} functions={functions || []} />}
        </Drawer>
      </>
    )
  )
}

interface ITestForm {
  clients: ClientData[];
  item: Test;
  close: () => void;
  functions: RuleFunction[];
}

const TestForm: React.FC<ITestForm> = ({ clients, item, close, functions }) => {
  const [data, setData] = useState<Test>(item);
  const [functionRelations, setFunctionRelations] = useState<TestRelation[]>(item.functionRelations || []);
  const [error, setErrors] = useState<(keyof Test)[] | undefined>(undefined);
  const [functionSelect, setFunctionSelect] = useState<string[] | undefined>(undefined);

  const queryClient = useQueryClient(); 

  const { data: categories, isPending: categoriesPending } = FetchCategories();
  const { data: businessProcesses, isPending: businessProcessesPending } = FetchBusinessProcesses();
  const { mutate: addTest, isPending: isAdding } = PostTest();
  const { mutate: updateTest, isPending: isUpdating } = PutTest();

  const handleSubmit = async () => {
    if (!validateForm()) return;
    let tempData = { ...data };
    if (tempData.testId === "new") {
      delete tempData.testId;
      tempData.functionRelations = functionRelations.map(m => ({ functionId: m.functionId, order: m.order }));
      addTest(tempData, {
        onSuccess: () => {
          toastMessage(`Test ${tempData.identifier} added successfully`);
          queryClient.invalidateQueries({ queryKey: ["tests"] });
          close();
        },
        onError: () => setErrors(["testId"])
      });
    } else {
      tempData.functionRelations = functionRelations;
      updateTest(tempData, {
        onSuccess: () => {
          toastMessage(`Test ${tempData.identifier} updated successfully`);
          queryClient.invalidateQueries({ queryKey: ["tests"] });
          close();
        },
        onError: () => setErrors(["testId"])
      });
    }
  }

  const getFunctions = () => functionRelations.sort((a, b) => a.order - b.order);

  const validateForm = () => {
    setErrors(undefined);
    let errorList: (keyof Test)[] = [];
    let check = checkFormValues(data, ["clientId", "identifier", "description", "versionComments", "versionStatus", "categoryId", "businessProcessId"]);
    let testCheck = functionRelations.length > 0;
    if (Array.isArray(check))
      errorList = check;
    if (!testCheck) errorList.push("functionRelations");
    if (errorList.length > 0) {
      setErrors(errorList);
      return false;
    }
    return true;
  }
  const handleOrderChange = (sourceIndex: number, destinationIndex: number) => {
    let newOrder = [...functionRelations];
    let [removed] = newOrder.splice(sourceIndex, 1);
    newOrder.splice(destinationIndex, 0, removed);
    setFunctionRelations(newOrder.map((m, i) => ({ ...m, order: i })));
  }

  function renderFunction(item: TestRelation, index: number, isDragging?: boolean | undefined, iskeyBoardDragging?: boolean | undefined) {
    return categoriesPending || businessProcessesPending
    ? <Loader loadingType="circular"></Loader> 
    : (
      <ListItem
        index={item.order}
        className="ap-list-item w-full p-4"
        key={item.functionId}
      >
        <div className="ap-list-item-draggable flex items-center gap-4 p-4">
          <div className="basis-22">
            <span className={`Appkit4-icon ${isDragging && iskeyBoardDragging ? 'icon-elevator-outline' : 'icon-menu-outline'}`}></span>
          </div>
          <div className="basis-1/2">
            <b>{item.function?.identifier}</b>
            <p>{item.function?.description}</p>
          </div>
          <div className="basis-1/2">
            <b>{RuleStatus[item.function?.versionStatus || 0]}</b>
            <p>{item.function?.versionComments}</p>
          </div>
          <div className="grow">
            <ToolTip content="Remove function" position="bottom">
              <span className="Appkit4-icon icon-delete-fill pointer" style={{ marginRight: 8 }} onClick={() => setFunctionRelations(functionRelations.filter(f => f.functionId !== item.functionId))}></span>
            </ToolTip>
          </div>
        </div>
      </ListItem>
    )
  }
  useEffect(() => {
    setErrors(undefined);
    setData(item);
    setFunctionRelations(item.functionRelations || []);
  }, [item, functions])
  useEffect(() => {
    if (functionRelations.length === 1) setData(d => ({ ...d, type: "SA" }));
    if (functionRelations.length > 1) setData(d => ({ ...d, type: "SoD" }));
  }, [functionRelations])
  return (
    <div className="ap-input-form">
      {
        (
          item.versionStatus === RuleStatus.Current && <FormWarning message="This bag is currently in use. Any changes that you do may change the results." />
        )
      }
      <form autoComplete="off">
        <h3 className="ap-font-medium">Details</h3>
        <div className="flex gap-4 flex-wrap">
          <div className="grow">
            <Input
              title="Name"
              type="text"
              value={data.identifier}
              onChange={(value: string) => setData({ ...data, identifier: value })}
              className="ap-field"
              required
              error={error?.includes("identifier")}
              errorNode={<ValidationError error="Name is required" />}
            />
          </div>
          <div className="basis-1/3">
            <Select
              placeholder="Status"
              data={Statuses}
              value={data.versionStatus}
              onSelect={value => setData({ ...data, versionStatus: value as RuleStatus })}
              required
              error={error?.includes("versionStatus")}
              errorNode={<ValidationError error="Status is required" />}
            />
          </div>
          <div className="basis-1/3 break-after-column">
            <Combobox
              placeholder="Client"
              data={clients.map(client => ({ label: client.name, value: client.clientId })).concat({ label: "Default", value: "00000000-0000-0000-0000-000000000000" })}
              value={data.clientId}
              onSelect={value => setData({ ...data, clientId: value.toString() })}
              className="ap-field"
              required
              error={error?.includes("clientId")}
              errorNode={<ValidationError error="Client is required" />}
            />
          </div>
          <div className="basis-1/3">
            <Combobox
              placeholder="Type"
              data={[{ label: "SA", value: "SA" }, { label: "SoD", value: "SoD" }]}
              value={data.type}
              className="ap-field"
              required
              disabled
              error={error?.includes("type")}
              errorNode={<ValidationError error="Type is required" />}
            />
          </div>
          <div className="basis-1/3">
            <Combobox
              placeholder="Category"
              data={categories?.map(m => ({ label: m.name, value: m.categoryId })) || []}
              value={data.categoryId}
              onSelect={value => setData({ ...data, categoryId: value.toString() })}
              className="ap-field"
              required
              error={error?.includes("categoryId")}
              errorNode={<ValidationError error="Category is required" />}
            />
          </div>
          <div className="grow break-after-column">
            <Combobox
              placeholder="Business process"
              data={businessProcesses?.map(m => ({ label: m.name, value: m.businessProcessId })) || []}
              value={data.businessProcessId}
              onSelect={value => setData({ ...data, businessProcessId: value.toString() })}
              className="ap-field"
              required
              error={error?.includes("businessProcessId")}
              errorNode={<ValidationError error="Business process is required" />}
            />
          </div>
          <div className="basis-1/2">
            <TextArea
              title="Description"
              value={data.description}
              onChange={(value: string) => setData({ ...data, description: value })}
              className="ap-field"
              required
              error={error?.includes("description")}
              errorNode={<ValidationError error="Description is required" />}
            />
          </div>
          <div className="grow">
            <TextArea
              title="Version comments"
              value={data.versionComments}
              onChange={(value: string) => setData({ ...data, versionComments: value })}
              className="ap-field"
              required
              error={error?.includes("versionComments")}
              errorNode={<ValidationError error="Version comments is required" />}
            />
          </div>
        </div>
        <h3 className="ap-font-medium">Functions</h3>
        <p>You can drag and drop functions to modify the order</p>
        <List
          data={getFunctions()}
          onDragEnd={(e) => handleOrderChange(e.source.index, e.destination.index)}
          draggable
          renderItem={renderFunction}
          itemKey="functionId"
        />
        <div className="flex gap-4 mt-4 min">
        <div className="basis-1/2">
            <Combobox
              placeholder="Select tests"
              data={
                functions.filter(f => f.clientId === "00000000-0000-0000-0000-000000000000" || f.clientId === data.clientId)
                  .sort((a, b) => a.identifier.localeCompare(b.identifier))
                  .map(m => ({
                    label: m.identifier,
                    value: m.functionId,
                    description: m.description,
                    versionStatus: m.versionStatus,
                    versionComments: m.versionComments,
                    client: clients.find(f => f.clientId === m.clientId)?.name || "Default"
                  }))
              }
              value={functionSelect}
              onSelect={(value) => Array.isArray(value) ? setFunctionSelect(value.map(m => m.toString())) : setFunctionSelect([value.toString()])}
              multiple
              showSelectAll={false}
              itemTemplate={(label: React.ReactNode, item: ItemDataType) => {
                return (
                  <div className="flex gap-4 items-center w-full">
                    <div className="basis-22">
                      <Checkbox checked={functionSelect?.includes(item.value as string)} />
                    </div>
                    <div className="basis-1/2">
                      <div style={{ padding: 8, marginRight: 16, display: "block", height: 30 }}><b>{item.label}</b></div>
                      <div style={{ marginLeft: 8, fontSize: 12, display: "block" }}>{item.description}</div>
                    </div>
                    <div>
                      <Badge value={item.client} />
                    </div>
                    <div className="grow">
                      <div style={{ padding: 8, marginRight: 16, display: "block", height: 30 }}><b>{Statuses.find(f => f.value === item.versionStatus)?.label}</b></div>
                      <div style={{ marginLeft: 8, fontSize: 12, display: "block" }}>{item.versionComments}</div>
                    </div>
                  </div>
                )
              }}
              valueTemplate={(value: SelectValue, item: ItemDataType | ItemDataType[]) => {
                return Array.isArray(item)
                  ? item.map(m => <Tag key={m.value}>{m.label}</Tag>)
                  : <Tag>{item.label}</Tag>
              }}
            />
          </div>
          <div>
            <Button kind="tertiary" onClick={() => {
              if (!functionSelect) return;
              setFunctionRelations([...functionRelations.filter(f => !functionSelect.includes(f.functionId)), ...functionSelect.map((m, i) => ({ order: functionRelations.length + i, functionId: m, testId: data.testId, function: functions.find(f => f.functionId === m) }))]);
              setFunctionSelect([]);
            }}>Add</Button>
          </div>
        </div>
        {error?.includes("functionRelations") && <ValidationError error="At least one function is required" />}
      </form>
      {error?.includes("testId") && <FormError error="Unable to save test" />}
      <div className="ap-footer flex gap-4">
        <div className="grow">
          <Button onClick={close} kind="secondary">Cancel</Button>
        </div>
        <div>
          <Button onClick={handleSubmit} kind="primary" loading={isAdding || isUpdating}>Save</Button>
        </div>
      </div>
    </div>
  )
}
export default TestEditor;
