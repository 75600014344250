import { FC, useCallback, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { getDate, sortValues, updateParams } from "services/common";
import AnalysisTemplate from "components/layout/analysisTemplate";
import ProcessList from "./processList";
import { AnalysisNavigator, LoaderWrapper } from "components/common/helpers";
import { Breadcrumb, BreadcrumbItem, DropdownButton, ValueType } from "@appkit4/react-components";
import UserGraph from "components/visualisation/userGraph";
import ByBusinessGraph from "components/visualisation/byBusinessGraph";
import { useAtomValue } from "jotai";
import { filterAtom } from "jotai/store";
import { FetchBusinessProcessList } from "queries/hooks/analysis/process";
import { FetchAnalysisList } from "queries/hooks/analysis/analysis";

const Process: FC = () => {
  const { analysisId } = useParams();
  const [comparisonId, setComparisonId] = useState<string | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();

  const userFilter = useAtomValue(filterAtom);

  const { data, isPending: isPendingProcess, error: errorProcess } = FetchBusinessProcessList(analysisId, userFilter, comparisonId);

  const { data: analysisList, isPending: isPendingAnalysis, error: errorAnalysis } = FetchAnalysisList(analysisId);

  const checkIfAnyFilters = useCallback(() => {
    return (userFilter.bukrs && userFilter.bukrs.length > 0) ||
      (userFilter.werks && userFilter.werks.length > 0) ||
      (userFilter.vkorg && userFilter.vkorg.length > 0);
  }, [userFilter]);

  return (
    <AnalysisTemplate>
      <Breadcrumb>
        <BreadcrumbItem>Analysis</BreadcrumbItem>
        <BreadcrumbItem>By business process</BreadcrumbItem>
      </Breadcrumb>
      <div className="flex flex-row">
        <div className="grow">
          <h1>Access Risks by Business Process</h1>
        </div>
        {analysisList && analysisList.length > 1 &&
          <div>
            <DropdownButton
              data={analysisList?.sort((a, b) => sortValues(b.extractionDate || "", a.extractionDate || "")).map(m => ({ value: m.analysisId, label: getDate(m.extractionDate || "") })) || []}
              style={{ marginTop: 24 }}
              kind="secondary"
              disabled={checkIfAnyFilters()}
              onSelect={(value: ValueType) => {
                setSearchParams(updateParams("comparisonAnalysisId", value.toString(), searchParams));
                setComparisonId(value.toString());
              }}
            >{searchParams.get("comparisonAnalysisId") ? getDate(analysisList?.find(f => f.analysisId === searchParams.get("comparisonAnalysisId"))?.extractionDate || "") : "Comparison"}</DropdownButton>
          </div>}
        {searchParams.get("comparisonAnalysisId") && (
          <div className="p-8">
            <span className="pointer" onClick={() => {
              setSearchParams();
              setComparisonId(undefined);
            }}>Clear</span>
          </div>
        )}
      </div>
      <AnalysisNavigator />
      <div className="grid 2xl:grid-cols-2 xl:grid-cols-1 gap-4">
        <div>
          <UserGraph />
        </div>
        <div>
          <ByBusinessGraph />
        </div>
      </div>
      <LoaderWrapper loading={[isPendingAnalysis, isPendingProcess]} errors={[errorAnalysis, errorProcess]} inline>
        <ProcessList
          businessProcesses={data || []}
        />
      </LoaderWrapper>
    </AnalysisTemplate>
  );
}

export default Process;
