import { saveAs } from 'file-saver';

const Excel = require('exceljs');

const handleValues = <T>(field: keyof T, row: T) => {
  switch(field)
  {
    default:
      return row[field];
  }
}

const exportExcel = async <T>(data: Array<T>, headers: Array<{ key: keyof T, header: string }>, fileName: string, pageHeader?: string[][], sheetNames?: Array<string>) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const wb = new Excel.Workbook();

  const ws = wb.addWorksheet("Data");

  ws.columns = headers;
  
  ws.addRows(data);

  const columnRow = ws.getRow(1);

  for(let i = 0; i < headers.length; i++) {
    let tempCell = columnRow.getCell(i + 1);
    tempCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF7D7D7D" },
      bgColor: { argb: "FFFFFFFF" }
    }
    tempCell.font = {
      color: { argb: "FFFFFFFF" }
    }
  }

  if(pageHeader)
    for(var header of pageHeader.reverse())
  {
    ws.spliceRows(1, 0, header);
    ws.getCell(1, 1).font = {
      bold: true
    }
  }
  
  ws.getRow(1).font = {
    font: "Arial",
    bold: true,
    size: 18,
  };

  wb.eachSheet((sheet: any) => {
    sheet.eachRow((row: any) => {
      row.eachCell((cell: any) => {
        if (!cell.font?.size) {
          cell.font = Object.assign(cell.font || {}, { size: 10 });
        }
        if (!cell.font?.name) {
          cell.font = Object.assign(cell.font || {}, { name: 'Arial' });
        }
      });
    });
  });

  ws.views = [
    {
      state: 'frozen',
      ySplit: pageHeader ? pageHeader.length+1 : 0
    }
  ];

  ws.autoFilter = {
    from: {
      row: pageHeader ? pageHeader.length+1 : 1,
      column: 1
    },
    to: {
      row: pageHeader ? pageHeader.length+1 : 1,
      column: headers.length
    }
  };

  const excelBuffer = await wb.xlsx.writeBuffer();
  const finalData = new Blob([excelBuffer], { type: fileType });
  saveAs(finalData, `${fileName}.xlsx`);
}

const exportClipboard = <T>(data: Array<T>, headers: Array<{ key: keyof T, header: string }>) => {
  var content: string[] = [
    headers.map(m => m.header).join("\t")
  ];

  for(let row of data) {
    var tempData: string[] = [];
    for(let field of headers.map(m => m.key)) {
      let item = handleValues(field, row);
      tempData.push(item ? item.toString() : "");
    }
    content.push(tempData.join("\t"));
  }

  navigator.clipboard.writeText(content.join("\n"));
}

export {
  exportClipboard,
  exportExcel
}